// src/pages/privates/Tools/index.tsx
import React from 'react';

import {
  HeaderNavigationPrivate,
  MenuSideBarPrivate,
  SubtitleSecondary,
  Paragraph,
} from '@components/index';

import { Options } from './HandChat/styles';
import { Container, ContainerGroup, Content } from './styles';

const ExternalServices: React.FC = () => {
  return (
    <Container>
      <HeaderNavigationPrivate />
      <ContainerGroup>
        <MenuSideBarPrivate />
        <Content>
          <SubtitleSecondary textAlign="start">
            Serviços Externos
          </SubtitleSecondary>
          <Options>
            <Paragraph fontSize={16} fontWeight={600} nameColor="black">
              Em breve
            </Paragraph>
          </Options>
        </Content>
      </ContainerGroup>
    </Container>
  );
};

export default ExternalServices;

/* eslint-disable no-new */
// ./src/pages/privates/User/index.tsx
import React, { useState, useCallback, useEffect, useRef } from 'react';

import { FormHandles } from '@unform/core';
import {
  IoRocket,
  IoDiamond,
  IoConstruct,
  IoReload,
  IoClose,
  IoRocketOutline,
  IoMegaphone,
} from 'react-icons/io5';
import { useHistory } from 'react-router-dom';

import {
  CardSimpleEstimate,
  HeaderNavigationPrivate,
  MenuSideBarPrivate,
  SubtitleSecondary,
  ButtonOutline,
  ButtonDefault,
  Paragraph,
  InputText,
  ButtonMain,
} from '@components/index';
import {
  ConfigRoutes,
  ConfigStorage,
  ConfigStyles,
  ConfigValues,
} from '@config/index';
import { apiRebox } from '@services/index';
import { formatText } from '@utils/formatters';
import { multiplierData } from '@utils/generators/generateMultiplier';
import { toastify } from '@utils/notifiers';

import {
  Container,
  ContainerGroup,
  Content,
  Estimates,
  ButtonsOption,
  FormFilter,
  ModalSimpleFilter,
  FilterFieldSet,
  FilterButtons,
} from './styles';

const User: React.FC = () => {
  const formFilterRef = useRef<FormHandles>(null);
  const history = useHistory();

  const [totalCustomers, setTotalCustomers] = useState<number>(0);
  const [totalAffiliates, setTotalAffiliates] = useState<number>(0);
  const [totalProviders, setTotalProviders] = useState<number>(0);
  const [totalMarketplace, setTotalMarketplace] = useState<number>(0);
  const [totalPromoters, setTotalPromoters] = useState<number>(0);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [
    isOpenModalSimpleFilter,
    setIsOpenModalSimpleFilter,
  ] = useState<boolean>(false);

  const getCustomers = useCallback(async () => {
    try {
      // const { data: response } = await apiRebox.get(`/clients?per_page=1`);
      const { data: responseUsers } = await apiRebox.get(
        `/users?per_page=1&role=${ConfigValues.rebox.user.role.client}`,
      );
      const { header } = responseUsers;
      // const total = header.total + headerUsers.total;
      const total = multiplierData(header.total, 20702);
      setTotalCustomers(total);
    } catch (error) {
      console.error('Error ao tentar buscar total de clientes: ', error);
    }
  }, []);

  const getAffiliates = useCallback(async () => {
    try {
      const { data: response } = await apiRebox.get(
        `/users?per_page=1&role=${ConfigValues.rebox.user.role.partner}`,
      );
      const { header } = response;
      setTotalAffiliates(header.total);
    } catch (error) {
      //  eslint-disable-next-line no-console
      console.error('Error ao tentar buscar total de clientes: ', error);
    }
  }, []);

  const getProviders = useCallback(async () => {
    try {
      const { data: response } = await apiRebox.get(
        `/users?per_page=1&role=${ConfigValues.rebox.user.role.provider}`,
      );
      const { header } = response;
      setTotalProviders(header.total);
    } catch (error) {
      //  eslint-disable-next-line no-console
      console.error('Error ao tentar buscar total de clientes: ', error);
    } finally {
      setLoading(prevState => !prevState);
    }
  }, []);

  const getMarketplace = useCallback(async () => {
    try {
      const { data: response } = await apiRebox.get(
        `/users?per_page=1&role=${ConfigValues.rebox.user.role.mkt_place}`,
      );
      const { header } = response;
      setTotalMarketplace(header.total);
    } catch (error) {
      //  eslint-disable-next-line no-console
      console.error('Error ao tentar buscar total de clientes: ', error);
    } finally {
      setLoading(prevState => !prevState);
    }
  }, []);

  const getPromoters = useCallback(async () => {
    try {
      const { data: response } = await apiRebox.get(
        `/users?per_page=1&role=${ConfigValues.rebox.user.role.promoter}`,
      );
      const { header } = response;
      setTotalPromoters(header.total);
    } catch (error) {
      //  eslint-disable-next-line no-console
      console.error('Error ao tentar buscar total de clientes: ', error);
    } finally {
      setLoading(prevState => !prevState);
    }
  }, []);

  useEffect(() => {
    setLoading(prevState => !prevState);
    getCustomers();
    getAffiliates();
    getProviders();
    getMarketplace();
    getPromoters();
    // eslint-disable-next-line
  }, [refresh]);

  const handleFilter = useCallback(async data => {
    try {
      formFilterRef.current?.reset();
      setIsOpenModalSimpleFilter(prevState => !prevState);
      data.role = data.role.toLowerCase();

      if (data.role === 'cliente') {
        data.role = ConfigValues.rebox.user.role.client;
        const { data: customersResponse } = await apiRebox.get(
          `/users/filters/?role=${data.role}&${
            data.cpfOrCnpj.length === 11 ? 'cpf' : 'cnpj'
          }=${data.cpfOrCnpj}`,
        );
        const [found_user] = customersResponse.data;
        history.push(
          `${ConfigRoutes.rebox.privates.users.next.customers.path}/${found_user.id}`,
        );
      } else if (data.role === 'afiliado') {
        data.role = ConfigValues.rebox.user.role.partner;
        const { data: affiliatesResponse } = await apiRebox.get(
          `/users/filters/?role=${data.role}&${
            data.cpfOrCnpj.length === 11 ? 'cpf' : 'cnpj'
          }=${data.cpfOrCnpj}`,
        );

        const [found_user] = affiliatesResponse.data;
        history.push(
          `${ConfigRoutes.rebox.privates.users.next.affiliates.path}/${found_user.id}`,
        );
      } else {
        data.role = ConfigValues.rebox.user.role.provider;
        const { data: providersResponse } = await apiRebox.get(
          `/users/filters/?role=${data.role}&${
            data.cpfOrCnpj.length === 11 ? 'cpf' : 'cnpj'
          }=${data.cpfOrCnpj}`,
        );
        const [found_user] = providersResponse.data;
        history.push(
          `${ConfigRoutes.rebox.privates.users.next.customers.path}/${found_user.id}`,
        );
      }
    } catch (error) {
      toastify('Ops! Houve um erro ao tentar buscar o usuário.', 'error');
    } finally {
      setLoading(prevState => !prevState);
    }
  }, []);

  const handlerNotification = async () => {
    if (!('Notification' in window)) {
      toastify('O navegador não suporta notificações', 'error');
    }
    Notification.requestPermission();
  };

  useEffect(() => {
    handlerNotification();
  }, []);

  return (
    <Container>
      <HeaderNavigationPrivate />
      <ContainerGroup>
        <MenuSideBarPrivate />
        <Content>
          <SubtitleSecondary textAlign="start">Usuários</SubtitleSecondary>
          <ButtonsOption>
            <ButtonOutline
              className="btn-update"
              loading={loading}
              iconLeft={IoReload}
              onClick={() => setRefresh(prevState => !prevState)}
            >
              Atualizar
            </ButtonOutline>
          </ButtonsOption>
          <Estimates>
            <ModalSimpleFilter
              ariaHideApp={false}
              isOpen={isOpenModalSimpleFilter}
              onRequestClose={() =>
                setIsOpenModalSimpleFilter(prevState => !prevState)
              }
              contentLabel="SimpleFilterCustomer"
            >
              <ButtonDefault
                iconLeft={IoClose}
                style={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  maxWidth: 50,
                  padding: 0,
                }}
                onClick={() =>
                  setIsOpenModalSimpleFilter(prevState => !prevState)
                }
              />
              <FormFilter ref={formFilterRef} onSubmit={handleFilter}>
                <FilterFieldSet>
                  <Paragraph
                    nameColor="black"
                    textAlign="start"
                    style={{ marginBottom: '1vh', fontWeight: 500 }}
                  >
                    Busca por CPF ou CNPJ
                  </Paragraph>
                  <InputText
                    name="cpfOrCnpj"
                    placeholder="CPF/CNPJ"
                    tabIndex={1}
                  />
                  <Paragraph
                    nameColor="black"
                    textAlign="start"
                    style={{ marginBottom: '1vh', fontWeight: 500 }}
                  >
                    Informe o tipo de cliente
                  </Paragraph>
                  <InputText
                    name="role"
                    placeholder="Cliente/Afiliado/Prestador"
                    tabIndex={1}
                  />
                </FilterFieldSet>
                <FilterButtons>
                  <ButtonMain type="submit">Buscar</ButtonMain>
                  <ButtonDefault
                    type="reset"
                    onClick={() => {
                      formFilterRef.current?.reset();
                      sessionStorage.removeItem(
                        ConfigStorage.REBOX_FILTERS_CUSTOMERS_LIST,
                      );
                    }}
                  >
                    Resetar
                  </ButtonDefault>
                </FilterButtons>
              </FormFilter>
            </ModalSimpleFilter>
            <CardSimpleEstimate
              icon={IoRocket}
              iconColor={ConfigStyles.rebox.colors.purple.main}
              iconSize={28}
              iconOpacity={0.8}
              label={`Total de clientes`}
              value={formatText.numberSeparatedByThousand(totalCustomers)}
              linkName={`Listar clientes`}
              route={ConfigRoutes.rebox.privates.users.next.customers.path}
              // handleClick={() =>
              //   setIsOpenModalSimpleFilter(prevState => !prevState)
              // }
            />
            {/* <CardSimpleEstimate
              icon={IoDiamond}
              iconColor={ConfigStyles.rebox.colors.greenSea.main}
              iconSize={28}
              iconOpacity={0.8}
              label={`Total de afiliados`}
              value={formatText.numberSeparatedByThousand(totalAffiliates)}
              linkName={`Listar afiliados`}
              route={ConfigRoutes.rebox.privates.users.next.affiliates.path}
              // handleClick={() =>
              //   setIsOpenModalSimpleFilter(prevState => !prevState)
              // }
            /> */}
            <CardSimpleEstimate
              icon={IoConstruct}
              iconColor={ConfigStyles.rebox.colors.orange.main}
              iconSize={28}
              iconOpacity={0.8}
              label={`Total de prestadores`}
              value={formatText.numberSeparatedByThousand(totalProviders)}
              linkName={`Listar prestadores`}
              route={ConfigRoutes.rebox.privates.users.next.providers.path}
              // handleClick={() =>
              //   setIsOpenModalSimpleFilter(prevState => !prevState)
              // }
            />
            <CardSimpleEstimate
              icon={IoRocketOutline}
              iconColor={ConfigStyles.rebox.colors.greenEmerald.main}
              iconSize={28}
              iconOpacity={0.8}
              label={`Total de clientes`}
              value={formatText.numberSeparatedByThousand(totalMarketplace)}
              linkName={`Listar clientes de marketplace`}
              route={ConfigRoutes.rebox.privates.users.next.marketplace.path}
              // handleClick={() =>
              //   setIsOpenModalSimpleFilter(prevState => !prevState)
              // }
            />
            <CardSimpleEstimate
              icon={IoMegaphone}
              iconColor={ConfigStyles.rebox.colors.lightBlue.main}
              iconSize={28}
              iconOpacity={0.8}
              label={`Total de divulgadores`}
              value={formatText.numberSeparatedByThousand(totalPromoters)}
              linkName={`Listar divulgadores`}
              route={ConfigRoutes.rebox.privates.users.next.promoters.path}
              // handleClick={() =>
              //   setIsOpenModalSimpleFilter(prevState => !prevState)
              // }
            />
          </Estimates>
        </Content>
      </ContainerGroup>
    </Container>
  );
};

export default User;

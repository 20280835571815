import React, { useState } from 'react';

import { ICustomerNotes } from '@components/forms/FormCustomerNotes/typing';
import LoadingList from '@components/loadings/LoadingList';
import Paragraph from '@components/texts/Paragraph';

import Item from './Item';

import { Container, Items, Labels, NothingFound, Table } from './styles';

interface IProps {
  notes: ICustomerNotes[];
  changeRemoveStatus(status: boolean): void;
}

const ListNotes: React.FC<IProps> = ({ notes, changeRemoveStatus }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Container>
      <Table>
        <Labels>
          <Paragraph nameColor="black" textAlign="start" fontSize={12}>
            Criado em
          </Paragraph>
          <Paragraph nameColor="black" textAlign="start" fontSize={12}>
            Anotação
          </Paragraph>
          <Paragraph nameColor="black" textAlign="start" fontSize={12}>
            Criado por
          </Paragraph>
          <Paragraph
            nameColor="black"
            textAlign="start"
            fontSize={12}
          ></Paragraph>
        </Labels>
        <Items>
          {isLoading ? (
            <LoadingList loading={isLoading} />
          ) : (
            <>
              {notes.length === 0 ? (
                <NothingFound>
                  <Paragraph nameColor="black" opacity={0.6}>
                    Nenhuma anotação foi encontrada
                  </Paragraph>
                </NothingFound>
              ) : (
                <>
                  {notes.map(note => (
                    <Item
                      key={note.id}
                      note={note}
                      changeRemoveStatus={changeRemoveStatus}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </Items>
      </Table>
    </Container>
  );
};

export default ListNotes;

import styled from 'styled-components';

export const Container = styled.div`
  padding: 2.5rem 2rem;
`;

export const Title = styled.p`
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 30px;
  color: ${({ theme }): string => theme.colors.blue.main};
  text-align: center;
  margin-bottom: 0.8rem;
`;

export const Header = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 1.5rem 0;
  background-color: ${({ theme }): string => theme.colors.white.main};

  .headerDescWrapper {
    padding-top: 0.2em;
  }

  .headerTitle {
    font-size: 1rem;
    color: ${({ theme }): string => theme.colors.white.main};
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .headerLabel {
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    color: ${({ theme }): string => theme.colors.white.main};
  }

  .headerDesc {
    font-size: 14px;
    line-height: 21px;
    color: ${({ theme }): string => theme.colors.white.main};
  }
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }): string => theme.colors.white.main};
  padding-top: 1rem;
`;

export const WrapperTitle = styled.p`
  width: 75%;
  margin: auto;
  font-size: 1.125rem;
  font-weight: 550;
  line-height: 21px;
  text-align: center;
`;

export const Button = styled.button`
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 17px;
  background-color: #00df;
  color: ${({ theme }): string => theme.colors.white.main};
  padding: 1.25rem;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

export const InputText = styled.input`
  width: 75%;
  margin-top: 1rem;
  padding: 0.675rem;
  background-color: ${({ theme }): string => theme.colors.white.main};
  border: 1.5px solid ${({ theme }): string => theme.colors.lightBlue.main};
  border-radius: 8px;
  font-size: 15px;
  font-weight: 350;
  color: ${({ theme }): string => theme.colors.blue.main};
`;

import { Form } from '@unform/web';
import styled, { css } from 'styled-components';

interface ISubTabLabelsButton {
  isActive?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white.main};
  text-align: center;
  margin: 3vh 0;
  width: 100%;
  padding: 2vh 4vw 6vh;
  border-radius: 10px;
`;

export const Sections = styled.div`
  width: 100%;
`;

export const SectionsGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3vh 0;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: flex-start;
  }
`;

export const SectionsItem = styled.div``;

export const DividingLine = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 4vh 0;
`;

export const FormCustomerNote = styled(Form)`
  width: 100%;
`;

export const SubTabs = styled.div`
  width: 100%;
  margin-top: 5vh;
`;

export const SubTabLabels = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SubTabLabelsDivisor = styled.div`
  height: 25px;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.black.opacity.low};
`;

export const SubTabLabelsButton = styled.button<ISubTabLabelsButton>`
  display: flex;
  align-items: center;
  padding: 0 3vw;
  transition: ease-in 300ms;

  p {
    color: ${({ theme }) => theme.colors.black.opacity.average};
  }

  :hover {
    p {
      color: ${({ theme }) => theme.colors.black.opacity.high};
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      p {
        color: ${({ theme }) => theme.colors.black.main};
        font-weight: 500;
      }
    `}

  @media (max-width: 960px) {
    p {
      font-size: ${({ theme }) => theme.fonts.size.paragraph.normal}px;
    }
  }
`;

// ./src/pages/privates/User/Customer/List/index.tsx
import React, { useEffect, useState, useRef, useCallback } from 'react';

import { FormHandles } from '@unform/core';
import { FiDownload, FiPaperclip } from 'react-icons/fi';
import {
  IoOptions,
  IoChevronBack,
  IoChevronForward,
  IoClose,
  IoDownloadOutline,
  IoArrowBack,
} from 'react-icons/io5';
import { useHistory } from 'react-router-dom';

import {
  HeaderNavigationPrivate,
  MenuSideBarPrivate,
  SubtitleSecondary,
  ListUserCustomers,
  InputText,
  InputMask,
  InputSelect,
  ButtonOutline,
  ButtonDefault,
  Paragraph,
  ButtonMain,
} from '@components/index';
import {
  ConfigStorage,
  ConfigRules,
  ConfigLabel,
  ConfigTransition,
  ConfigValues,
} from '@config/index';
import User from '@models/User';
import { apiRebox, apiIbge } from '@services/index';
import {
  formatCellphone,
  formatCNPJ,
  formatCPF,
  formatDate,
  formatText,
} from '@utils/formatters';
import { generateDate, generateNumber } from '@utils/generators';
import { multiplierData } from '@utils/generators/generateMultiplier';
import { toastify } from '@utils/notifiers';

import {
  IResponseUsers,
  IFilterCustomersFormData,
  ISearchCustomersFormData,
  ISelect,
  IIbgeStates,
  IIbgeCities,
  IExportData,
} from './typing';

import {
  Container,
  ContainerGroup,
  Content,
  Options,
  OptionsGroup,
  FormSearch,
  FormPage,
  Pagination,
  PaginationGroup,
  PaginationGroupText,
  ModalFilter,
  ModalSimpleFilter,
  FormFilter,
  FilterFieldGroup,
  FilterFieldSet,
  FilterButtons,
  ModalExportData,
  Progress,
  ProgressBar,
  ProgressText,
  ButtonExportToExcel,
} from './styles';

const CustomerList: React.FC = () => {
  const { goBack } = useHistory();
  const formSearchRef = useRef<FormHandles>(null);
  const formFilterRef = useRef<FormHandles>(null);
  const formPageRef = useRef<FormHandles>(null);
  const formPageSecondaryRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [exporting, setExporting] = useState<boolean>(false);
  const [totalValue, setTotalValue] = useState<number>(1);
  const [progressExportData, setProgressExportData] = useState<number>(0);
  const [customers, setCustomers] = useState<IResponseUsers>();
  const [page, setPage] = useState<number>(
    Number.parseInt(
      sessionStorage.getItem(
        ConfigStorage.REBOX_PAGINATION_CUSTOMERS_LIST_PAGE,
      ) || '1',
      10,
    ),
  );
  const [totalPages, setTotalPages] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [exportData, setExportData] = useState<IExportData[]>([]);
  const [filter, setFilter] = useState<IFilterCustomersFormData>(
    JSON.parse(
      sessionStorage.getItem(ConfigStorage.REBOX_FILTERS_CUSTOMERS_LIST) ||
        '{}',
    ),
  );
  const [states, setStates] = useState<ISelect[]>([]);
  const [cities, setCities] = useState<ISelect[]>([]);
  const [isDisabledFilter, setIsDisabledFilter] = useState<boolean>(true);
  const [
    isOpenModalSimpleFilter,
    setIsOpenModalSimpleFilter,
  ] = useState<boolean>(false);
  const [isOpenModalFilter, setIsOpenModalFilter] = useState<boolean>(false);
  const [isOpenModalExportData, setIsOpenModalExportData] = useState<boolean>(
    false,
  );

  const buildTheQueryUrl = (value: number): string => {
    let url = `/users?role=${ConfigValues.rebox.user.role.client}&page=${value}&per_page=10&details=all`;

    const {
      current_payments_status,
      name,
      cpfcnpj,
      license_plate,
      tel,
      state,
      city,
      status,
      defined_period,
      period_end,
      period_start,
    } = filter;

    if (current_payments_status) {
      url = `${url}&current_payments_status=${current_payments_status}`;
    }

    if (state && city) {
      url = `${url}&state=${state.toLowerCase()}&city=${city.toLowerCase()}`;
    } else if (state) {
      url = `${url}&state=${state.toLowerCase()}`;
    }

    if (name) {
      url = `/users/?name=${name}&page=${value}`;
    }
    if (license_plate) {
      url = `/users/?license_plate=${license_plate}`;
    }
    if (tel) {
      url = `/users/?cellphone=${tel}`;
    }
    if (cpfcnpj) {
      url = `/users/?${cpfcnpj.length < 12 ? 'cpf' : 'cnpj'}=${cpfcnpj}`;
    }
    if (status) {
      url = `/users/?status=${status}&page=${value}`;
    }

    if (defined_period) {
      if (defined_period === 'OTHER') {
        url = `${url}&period_start=${period_start}&period_end=${period_end}`;
      } else {
        const [today] = generateDate.now().split(' ');
        if (defined_period === '0') {
          url = `${url}&period_start=${today} 00:00&period_end=${today} 23:59`;
        } else {
          const [before] = generateDate
            .beforeDays(Number.parseInt(defined_period, 10))
            .split(' ');
          url = `${url}&period_start=${before} 00:00&period_end=${today} 23:59`;
        }
      }
    }

    return url;
  };

  const buildTheQueryUrlForClients = (value: number): string => {
    let url = `/clients?role=${ConfigValues.rebox.user.role.client}&page=${value}&per_page=${ConfigRules.rebox.pagination.users.itemLimit}`;

    const {
      name,
      cpf,
      license_plate,
      tel,
      state,
      city,
      status,
      defined_period,
      period_end,
      period_start,
    } = filter;

    if (state && city) {
      url = `${url}&state=${state.toLowerCase()}&city=${city.toLowerCase()}`;
    } else if (state) {
      url = `${url}&state=${state.toLowerCase()}`;
    }

    if (name) {
      url = `/users/?name=${name}&page=${value}`;
    }
    if (license_plate) {
      url = `/users/?license_plate=${license_plate}`;
    }
    if (tel) {
      url = `/users/?cellphone=${tel}`;
    }
    if (cpf) {
      url = `/users/?${cpf}`;
    }
    if (status) {
      url = `/users/?status=${status}&page=${value}`;
    }

    if (defined_period) {
      if (defined_period === 'OTHER') {
        url = `${url}&period_start=${period_start}&period_end=${period_end}`;
      } else {
        const [today] = generateDate.now().split(' ');
        if (defined_period === '0') {
          url = `${url}&period_start=${today} 00:00&period_end=${today} 23:59`;
        } else {
          const [before] = generateDate
            .beforeDays(Number.parseInt(defined_period, 10))
            .split(' ');
          url = `${url}&period_start=${before} 00:00&period_end=${today} 23:59`;
        }
      }
    }

    return url;
  };

  const getStates = useCallback(async () => {
    try {
      const { data: response } = await apiIbge.get(
        `/localidades/estados?orderBy=nome`,
      );

      const ibgeStates: IIbgeStates[] = response;
      const statesUpdated: ISelect[] = [
        {
          label: 'Todos',
          value: 'UNDEFINED',
        },
      ];

      ibgeStates.forEach(element => {
        statesUpdated.push({
          label: element.sigla,
          value: element.sigla,
        });
      });

      setStates(statesUpdated);
    } catch (error) {
      toastify(`Houve um error ao buscar os estados.`, 'error');
    }
  }, []);

  const getCity = useCallback(async (uf: string) => {
    try {
      const { data: response } = await apiIbge.get(
        `localidades/estados/${uf}/municipios`,
      );

      const ibgeCities: IIbgeCities[] = response;
      const citiesUpdated: ISelect[] = [
        {
          label: 'Todos',
          value: 'UNDEFINED',
        },
      ];

      ibgeCities.forEach(element => {
        citiesUpdated.push({
          label: element.nome,
          value: element.nome.toLowerCase(),
        });
      });

      setCities(citiesUpdated);
    } catch (error) {
      toastify(`Houve um error ao buscar os municípios de ${uf}`, 'error');
    }
  }, []);

  const getCustomers = async (desiredPage = 1) => {
    try {
      setLoading(prevState => !prevState);

      const builtUrl = buildTheQueryUrl(desiredPage);
      const { data: responseCustomers } = await apiRebox.get(builtUrl);
      setCustomers(responseCustomers);

      const { header }: IResponseUsers = responseCustomers;
      const total = multiplierData(header.total, 20702);
      setTotalValue(total);
      setTotalPages(Math.ceil(total / header.per_page));
      formPageRef.current?.setData({ currentPage: desiredPage });
      formPageSecondaryRef.current?.setData({ currentPage: desiredPage });
    } catch (error) {
      toastify('Ops! Houve um erro ao tentar buscar clientes.', 'error');
    } finally {
      setLoading(prevState => !prevState);
    }
  };

  const handleSearchCustomers = useCallback(
    (data: ISearchCustomersFormData) => {
      formFilterRef.current?.reset();
      setSearch(data.search.toLowerCase());
      setPage(1);
    },
    [],
  );

  const handleFilterCustomers = useCallback(
    (data: IFilterCustomersFormData) => {
      formSearchRef.current?.reset();

      const allDataFilter: IFilterCustomersFormData = {
        cpfcnpj:
          data.cpfcnpj === 'UNDEFINED'
            ? ''
            : formatCPF.removeMask(data.cpfcnpj || ''),
        license_plate:
          data.license_plate === 'UNDEFINED' ? '' : data.license_plate,
        name: data.name === 'UNDEFINED' ? '' : data.name,
        tel:
          data.tel === 'UNDEFINED'
            ? ''
            : formatCellphone.removeMask(data.tel || ''),
        state: data.state === 'UNDEFINED' ? '' : data.state,
        status:
          data.current_payments_status === 'LEAD'
            ? data.current_payments_status
            : '',
        city: data.city === 'UNDEFINED' ? '' : data.city,
        defined_period:
          data.defined_period === 'UNDEFINED' ? '' : data.defined_period,
        period_start: data.period_start
          ? `${formatDate.removeMask(data.period_start)} 00:00`
          : '',
        period_end: data.period_end
          ? `${formatDate.removeMask(data.period_end)} 23:59`
          : '',
        current_payments_status:
          data.current_payments_status === 'UNDEFINED'
            ? ''
            : data.current_payments_status,
      };

      sessionStorage.setItem(
        ConfigStorage.REBOX_FILTERS_CUSTOMERS_LIST,
        JSON.stringify(allDataFilter),
      );
      setFilter(allDataFilter);

      setIsOpenModalFilter(false);
      setIsOpenModalSimpleFilter(false);

      setPage(1);
    },
    [],
  );

  const handleExportData = async () => {
    try {
      setExporting(prevState => !prevState);
      const customersExportData: IExportData[] = [];
      const arrayUrls: string[] = [];
      const numberPages = Math.ceil(
        customers?.header.total ||
          ConfigRules.rebox.pagination.users.itemLimit /
            ConfigRules.rebox.pagination.users.itemLimit,
      );

      for (let i = 1; i <= numberPages; i++) {
        arrayUrls.push(buildTheQueryUrl(i));
      }

      const percentage: number = generateNumber.getPercentageByValue(
        arrayUrls.length,
        1,
      );

      for await (const url of arrayUrls) {
        const { data: responseCustomer } = await apiRebox.get(url);
        const customersExported: User[] = responseCustomer.data;

        customersExported.forEach(item => {
          const [address] = item.adresses;

          if (address) {
            customersExportData.push({
              user_name: item.name,
              user_cpf_or_cnpj: item.cpf || item.cnpj || '',
              user_date_of_birth: formatDate.addMask(item.date_of_birth || ''),
              user_email: item.email,
              user_cellphone: item.cellphone,
              user_status: ConfigTransition.rebox_user_status[item.status],
              user_epharma: item.epharma_holder_id
                ? 'Cadastrado'
                : 'Aguardando',
              user_adresses_state: address.state.toUpperCase(),
              user_adresses_city: address.city.toUpperCase(),
            });
          }
        });

        setProgressExportData(prevValue => {
          if (prevValue < 99) {
            const progress: number = prevValue + percentage;
            const [units, decimals] = progress.toFixed(1).split('.');
            const progressUpdated = Number.parseFloat(
              decimals ? `${units}.${decimals.substring(0, 2)}` : units,
            );
            return progressUpdated;
          }
          return 99;
        });
      }
      setProgressExportData(100);
      setExportData(customersExportData);
    } catch (error: any) {
      toastify('Sinto muito, não conseguimos exportar os dados.', 'error');
      setProgressExportData(0);
      setExportData([]);
    } finally {
      setExporting(prevState => !prevState);
    }
  };

  const handleDownloadFile = useCallback(() => {
    setProgressExportData(0);
    setExportData([]);
    setIsOpenModalExportData(prevState => !prevState);
    toastify('Download do relatório de clientes foi iniciado!', 'success');
  }, []);

  const cleanFilter = useCallback(() => {
    formFilterRef.current?.reset();
    sessionStorage.removeItem(ConfigStorage.REBOX_FILTERS_CUSTOMERS_LIST);
    setFilter({} as IFilterCustomersFormData);
    setIsOpenModalFilter(false);
  }, []);

  const handleGoBack = () => {
    sessionStorage.removeItem(
      ConfigStorage.REBOX_PAGINATION_CUSTOMERS_LIST_PAGE,
    );
    sessionStorage.removeItem(ConfigStorage.REBOX_FILTERS_CUSTOMERS_LIST);
    goBack();
  };

  useEffect(() => {
    getCustomers(page);
    sessionStorage.setItem(
      ConfigStorage.REBOX_PAGINATION_CUSTOMERS_LIST_PAGE,
      `${page}`,
    );
  }, [page, search, filter]);

  useEffect(() => {
    getStates();
  }, []);

  useEffect(
    () => () => {
      sessionStorage.clear();
    },
    [],
  );

  const simpleFilter: { value: string; label: string }[] = [
    {
      value: 'name',
      label: 'Nome',
    },
    {
      value: 'cpfcnpj',
      label: 'CPF ou CNPJ',
    },
    {
      value: 'license_plate',
      label: 'Placa',
    },
    {
      value: 'tel',
      label: 'Telefone',
    },
  ];

  return (
    <Container>
      <HeaderNavigationPrivate />
      <ContainerGroup>
        <MenuSideBarPrivate />
        <Content>
          <SubtitleSecondary textAlign="start">Clientes</SubtitleSecondary>
          <Options>
            <OptionsGroup>
              <ButtonDefault iconLeft={IoArrowBack} onClick={handleGoBack} />
            </OptionsGroup>
          </Options>
          <Pagination>
            <ButtonDefault
              style={{
                borderRadius: '8px 0 0 8px',
                maxWidth: 100,
              }}
              disabled={page === 1}
              isDisable={page === 1}
              onClick={() => setPage(1)}
            >
              Primeira
            </ButtonDefault>
            <ButtonDefault
              iconLeft={IoChevronBack}
              style={{
                borderRadius: '0',
                maxWidth: 30,
                padding: '0 5px',
                margin: '0 5px',
              }}
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            />
            <PaginationGroup>
              <FormPage
                ref={formPageSecondaryRef}
                onSubmit={() => []}
                initialData={{ currentPage: page }}
              >
                <InputText
                  name="currentPage"
                  type="number"
                  min="1"
                  onChange={event => {
                    if (!(event.target.value === '')) {
                      setPage(Number.parseInt(event.target.value, 10));
                    }
                  }}
                />
              </FormPage>
              <PaginationGroupText>de {totalPages}</PaginationGroupText>
            </PaginationGroup>

            <ButtonDefault
              iconLeft={IoChevronForward}
              style={{
                borderRadius: '0',
                maxWidth: 30,
                padding: '0 5px',
                margin: '0 5px',
              }}
              disabled={page === totalPages}
              onClick={() => setPage(page + 1)}
            />
            <ButtonDefault
              style={{
                borderRadius: '0 8px 8px 0',
                maxWidth: 100,
              }}
              disabled={page === totalPages}
              isDisable={page === totalPages}
              onClick={() => setPage(totalPages)}
            >
              Última
            </ButtonDefault>
          </Pagination>
          <ListUserCustomers
            users={customers?.data}
            loading={loading}
            showTotal={true}
            totalValue={totalValue}
          />
          <Pagination>
            <ButtonDefault
              style={{
                borderRadius: '8px 0 0 8px',
                maxWidth: 100,
              }}
              disabled={page === 1}
              isDisable={page === 1}
              onClick={() => setPage(1)}
            >
              Primeira
            </ButtonDefault>
            <ButtonDefault
              iconLeft={IoChevronBack}
              style={{
                borderRadius: '0',
                maxWidth: 30,
                padding: '0 5px',
                margin: '0 5px',
              }}
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            />
            <PaginationGroup>
              <FormPage
                ref={formPageRef}
                onSubmit={() => []}
                initialData={{ currentPage: page }}
              >
                <InputText
                  name="currentPage"
                  type="number"
                  min="1"
                  onChange={event => {
                    if (!(event.target.value === '')) {
                      setPage(Number.parseInt(event.target.value, 10));
                    }
                  }}
                />
              </FormPage>
              <PaginationGroupText>de {totalPages}</PaginationGroupText>
            </PaginationGroup>

            <ButtonDefault
              iconLeft={IoChevronForward}
              style={{
                borderRadius: '0',
                maxWidth: 30,
                padding: '0 5px',
                margin: '0 5px',
              }}
              disabled={page === totalPages}
              onClick={() => setPage(page + 1)}
            />
            <ButtonDefault
              style={{
                borderRadius: '0 8px 8px 0',
                maxWidth: 100,
              }}
              disabled={page === totalPages}
              isDisable={page === totalPages}
              onClick={() => setPage(totalPages)}
            >
              Última
            </ButtonDefault>
          </Pagination>
        </Content>
      </ContainerGroup>
      <ModalSimpleFilter
        ariaHideApp={false}
        isOpen={isOpenModalSimpleFilter}
        onRequestClose={() => {
          setIsOpenModalSimpleFilter(prevState => !prevState);
        }}
        contentLabel="SimpleFilterCustomer"
      >
        <ButtonDefault
          iconLeft={IoClose}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            maxWidth: 50,
            padding: 0,
          }}
          onClick={() => setIsOpenModalSimpleFilter(prevState => !prevState)}
        />
        <FormFilter ref={formFilterRef} onSubmit={handleFilterCustomers}>
          {search === 'name' && (
            <FilterFieldSet>
              <Paragraph
                nameColor="black"
                textAlign="start"
                style={{ marginBottom: '1vh', fontWeight: 500 }}
              >
                Busca por Nome
              </Paragraph>
              <InputText name="name" placeholder="Nome" tabIndex={1} />
            </FilterFieldSet>
          )}
          {search === 'cpfcnpj' && (
            <FilterFieldSet>
              <Paragraph
                nameColor="black"
                textAlign="start"
                style={{ marginBottom: '1vh', fontWeight: 500 }}
              >
                Busca por CPF / CNPJ
              </Paragraph>
              <InputText
                name="cpfcnpj"
                placeholder="CPF ou CNPJ"
                tabIndex={1}
              />
            </FilterFieldSet>
          )}
          {search === 'tel' && (
            <FilterFieldSet>
              <Paragraph
                nameColor="black"
                textAlign="start"
                style={{ marginBottom: '1vh', fontWeight: 500 }}
              >
                Busca por número de Telefone
              </Paragraph>
              <InputText name="tel" placeholder="Telefone" tabIndex={1} />
            </FilterFieldSet>
          )}
          {search === 'license_plate' && (
            <FilterFieldSet>
              <Paragraph
                nameColor="black"
                textAlign="start"
                style={{ marginBottom: '1vh', fontWeight: 500 }}
              >
                Busca por Placa
              </Paragraph>
              <InputText
                name="license_plate"
                placeholder="Placa"
                tabIndex={1}
              />
            </FilterFieldSet>
          )}
          <ButtonMain style={{ marginTop: '0.5rem' }} type="submit">
            Filtrar
          </ButtonMain>
        </FormFilter>
      </ModalSimpleFilter>
      <ModalExportData
        isOpen={isOpenModalExportData}
        onRequestClose={() => setIsOpenModalExportData(prevState => !prevState)}
        contentLabel="ExportData"
      >
        <ButtonDefault
          iconLeft={IoClose}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            maxWidth: 50,
            padding: 0,
          }}
          onClick={() => setIsOpenModalExportData(prevState => !prevState)}
        />
        <Paragraph style={{ fontWeight: 600, marginBottom: '1.5vh' }}>
          Exportar resultado
        </Paragraph>
        <Paragraph nameColor="black" opacity={0.6}>
          Os registros retornados do filtro ou da pesquisa serão exportados para
          um arquivo excel.
        </Paragraph>
        <Progress percentage={progressExportData}>
          <ProgressText>{progressExportData}%</ProgressText>
          <ProgressBar className="active" />
          <ProgressBar />
        </Progress>
        {progressExportData < 100 && (
          <ButtonMain
            loading={exporting}
            style={{ maxWidth: 200, margin: '0 auto' }}
            onClick={() => handleExportData()}
          >
            Iniciar
          </ButtonMain>
        )}
        {progressExportData === 100 && (
          <ButtonExportToExcel
            headers={ConfigLabel.rebox.export.excel.customer}
            data={exportData}
            filename={`clientes-${generateDate.now().split(' ')[0]}.xls`}
            onClick={handleDownloadFile}
          >
            <IoDownloadOutline size={18} />
            Baixar
          </ButtonExportToExcel>
        )}
      </ModalExportData>
    </Container>
  );
};

export default CustomerList;

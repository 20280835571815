import styled, { css } from 'styled-components';

interface IDropdownContainer {
  isActive: boolean;
}

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white.main};
  height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  margin: 0 auto;

  width: 100%;

  padding: 0 20px;

  @media (min-width: 1024px) {
    padding: 0;
  }

  @media (max-width: 479.9px) {
    padding: 0 10vw;
  }
`;

export const Information = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8vh 0;

  > svg {
    max-width: 260px;
    max-height: 260px;
  }

  @media (max-width: 768px) {
    padding: 6vh 0 3vh;
    > svg {
      display: none;
    }
  }
`;

export const InformationText = styled.div`
  padding: 0 4vw;

  > form {
    padding: 4vh 0;
    margin: 0 auto;
    max-width: 350px;

    > button {
      margin-top: 2vh;
    }
  }
`;

export const InformationTextTitle = styled.h1`
  text-align: center;
  color: ${({ theme }) => theme.colors.blue.main};
  font-size: ${({ theme }) => theme.fonts.size.subtitle.large}px;
  font-weight: 700;
`;

export const InformationTextSubtitle = styled.h2`
  font-weight: normal;
  font-size: ${({ theme }) => theme.fonts.size.paragraph.extraLarge}px;
  line-height: 29px;
  color: ${({ theme }) => theme.colors.blue.opacity.high};

  max-width: 805px;

  margin-top: 22px;
`;

export const SectionsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10vw;
  padding: 3vh 0;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

export const SectionsItem = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    padding: 2vh 0;
  }
`;

export const SectionsItemGroup = styled.div`
  display: grid;
  grid-template-columns: 1.8fr 1fr 0.2fr;
  align-items: center;
  width: 500px;
  padding: 5vh 0 3vh;
`;

export const PlacesAutocompleteGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropdownContainer = styled.div<IDropdownContainer>`
  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid rgba(0, 0, 0, 0.2);
    `}
`;

export const DropdownContainerLoading = styled.div`
  padding: 10px 2vw;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fonts.size.paragraph.large}px;
  color: ${({ theme }) => theme.colors.black.main};
`;

export const DropdownContainerGroup = styled.div``;

export const DropdownContainerSuggestions = styled.div<IDropdownContainer>`
  padding: 10px 2vw;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fonts.size.paragraph.large}px;
  color: ${({ theme }) => theme.colors.black.main};
  ${({ isActive }) =>
    isActive &&
    css`
      :hover {
        background-color: ${({ theme }) => theme.colors.black.opacity.low};
        cursor: pointer;
      }
    `}
`;

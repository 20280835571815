// ./src/routes/index.tsx
import React, { lazy, Suspense } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import {
  CardLogIn,
  ListSalesContracts,
  LoadingSuspense,
} from '@components/index';
import { JustRedirect } from '@components/newComponents';
import { ConfigRoutes } from '@config/index';

// Pages
import {
  LandingPage,
  SignIn,
  RecoverPassword,
  About,
  Plan,
  PrivacyPolicy,
  Register,
  Checkout,
  Thanks,
  Debts,
  // Assistance,
  // Panel,
  // Profile,
  // Contract,
  // ContractNew,
  ContractShow,
  // Called,
  // CalledNew,
  // CalledShow,
  // Help,
  // Notification,
  // Setting,
  // VehicleShow,
  NotFound,
  Maintenance,
  Home,
  ClientManual,
  CustomerList,
  User,
  AffiliateList,
  CustomerShow,
  AffiliateShow,
  ProviderList,
  ProviderShow,
  Contract,
  ContractList,
  Financial,
  Charge,
  ChargeShow,
  Called,
  CalledList,
  CalledShow,
  Dashboard,
  HandChat,
  ShortMaintenance,
} from '@pages/index';
// import ContractShow from '@pages/private/Contract/Show';
import ExternalServices from '@pages/private/ExternalServices';
import MarketplaceList from '@pages/private/User/Marketplace/List';
import MarketplaceShow from '@pages/private/User/Marketplace/Show';
import PromotersList from '@pages/private/User/Promoters/List';
import PromoterShow from '@pages/private/User/Promoters/Show';
import Tools from '@pages/private/User/Tools';
import Communication from '@pages/private/User/Tools/Communication';
import Notifications from '@pages/private/User/Tools/Notifications';
import Product from '@pages/private/User/Tools/Product';
import Service from '@pages/private/User/Tools/Service';
import Assistance from '@pages/public/Assistance';
import HomePromotion from '@pages/public/HomePromotion';
import { Affiliate } from '@pages/public/LandingPage/styles';

import PrivateRoute from './private';

const Routes: React.FC = () => (
  <Switch>
    {/* Atividades liberadas a todos */}
    <Route
      path={ConfigRoutes.rebox.publics.landingPage.path}
      // component={Maintenance}
      component={Home}
      exact
    />
    <Route
      path={ConfigRoutes.rebox.publics.landingPage.next.home.path}
      component={Home}
      exact
    />
    <Route
      path={ConfigRoutes.rebox.publics.landingPage.next.promo.path}
      component={HomePromotion}
      exact
    />
    {/* <Route
      path={ConfigRoutes.rebox.publics.signIn.path}
      component={SignIn}
      exact
    /> */}
    {/* <Route
      path={ConfigRoutes.rebox.publics.recoverPassword.path}
      component={RecoverPassword}
      exact
    /> */}
    {/* <Route
      path={ConfigRoutes.rebox.publics.about.path}
      component={About}
      exact
    /> */}
    {/* <Route
      path={ConfigRoutes.rebox.publics.plan.path}
      component={JustRedirect}
      exact
    /> */}
    <Route
      path={ConfigRoutes.rebox.publics.clientManual.path}
      component={ClientManual}
      exact
    />
    <Route
      path={ConfigRoutes.rebox.publics.privacyPolicy.path}
      component={PrivacyPolicy}
      exact
    />
    {/* <Route
      path={ConfigRoutes.rebox.publics.register.path}
      component={Register}
      exact
    /> */}
    <Route
      path={ConfigRoutes.rebox.publics.checkout.path}
      component={Checkout}
      exact
    />
    <Route
      path={ConfigRoutes.rebox.publics.checkout.next.thanks.path}
      component={Thanks}
      exact
    />
    <Route
      path={ConfigRoutes.rebox.publics.debts.path}
      component={Debts}
      exact
    />
    <Route
      path={ConfigRoutes.rebox.publics.assistance.path}
      component={Assistance}
      exact
    />
    <Route
      path={ConfigRoutes.rebox.privates.admin.path}
      component={ShortMaintenance}
      exact
    />

    {/* Atividades apenas para pessoal autorizado */}
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.users.path}
      component={User}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.users.next.customers.path}
      component={CustomerList}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.users.next.customers.next.show.path}
      component={CustomerShow}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.users.next.affiliates.path}
      component={AffiliateList}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.users.next.affiliates.next.show.path}
      component={AffiliateShow}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.users.next.providers.path}
      component={ProviderList}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.users.next.providers.next.show.path}
      component={ProviderShow}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.sales.path}
      component={Contract}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.sales.next.contracts.path}
      component={ContractList}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.sales.next.contracts.next.show.path}
      component={ContractShow}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.financial.path}
      component={Financial}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.financial.next.charges.path}
      component={Charge}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.financial.next.charges.next.show.path}
      component={ChargeShow}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.called.path}
      component={Called}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.called.next.drives.path}
      component={CalledList}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.called.next.show.path}
      component={CalledShow}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.dashboad.path}
      component={Dashboard}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.users.next.marketplace.path}
      component={MarketplaceList}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.users.next.marketplace.next.show.path}
      component={MarketplaceShow}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.users.next.promoters.path}
      component={PromotersList}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.users.next.promoters.next.show.path}
      component={PromoterShow}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.tools.path}
      component={Tools}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.tools.next.communication.path}
      component={Communication}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.tools.next.notifications.path}
      component={Notifications}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.tools.next.products.path}
      component={Product}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.tools.next.services.path}
      component={Service}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.externalServices.path}
      component={ExternalServices}
      exact
    />
    <PrivateRoute
      path={ConfigRoutes.rebox.privates.externalServices.next.handChat.path}
      component={HandChat}
      exact
    />
    {/* <PrivateRoute
        path={ConfigRoutes.rebox.privates.panel.path}
        component={Panel}
        exact
      />
      <PrivateRoute
        path={ConfigRoutes.rebox.privates.profile.path}
        component={Profile}
        exact
      />
      <PrivateRoute
        path={ConfigRoutes.rebox.privates.contract.path}
        component={Contract}
        exact
      />
      <PrivateRoute
        path={ConfigRoutes.rebox.privates.contract.next.new.path}
        component={ContractNew}
        exact
      />
      <PrivateRoute
        path={ConfigRoutes.rebox.privates.contract.next.show.path}
        component={ContractShow}
        exact
      />
      <PrivateRoute
        path={ConfigRoutes.rebox.privates.called.path}
        component={Called}
        exact
      />
      <PrivateRoute
        path={ConfigRoutes.rebox.privates.called.next.new.path}
        component={CalledNew}
        exact
      />
      <PrivateRoute
        path={ConfigRoutes.rebox.privates.called.next.show.path}
        component={CalledShow}
        exact
      />
      <PrivateRoute
        path={ConfigRoutes.rebox.privates.help.path}
        component={Help}
        exact
      />
      <PrivateRoute
        path={ConfigRoutes.rebox.privates.notification.path}
        component={Notification}
        exact
      />
      <PrivateRoute
        path={ConfigRoutes.rebox.privates.setting.path}
        component={Setting}
        exact
      />
      <PrivateRoute
        path={ConfigRoutes.rebox.privates.vehicle.next.show.path}
        component={VehicleShow}
        exact
      /> */}
    <Route path="*" component={NotFound} />
  </Switch>
);

export default Routes;

import { useEffect } from 'react';

const JustRedirect = () => {
  useEffect(() => {
    window.location.href = 'http://planos.rebox.com.br';
  }, []);
  return <div></div>;
};

export default JustRedirect;

import { validatorCpf, validatorPhone } from '@utils/validators';

export const validateFilterType = (type: string, value: string): void => {
  if (type === 'CPF' && !validatorCpf.check(value)) {
    throw new Error('CPF invalido');
  }
  if (type === 'PHONE' && !validatorPhone.check(value)) {
    throw new Error('Telefone invalido');
  }
};

export const validateTextarea = (text: string): void => {
  if (text.length === 0) {
    throw new Error('O campo de mensagem não pode estar vazio');
  }
};

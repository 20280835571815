/* eslint-disable prettier/prettier */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';

import {
  faCar,
  faCaretUp,
  faMotorcycle,
  faTruckPickup,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import CardPlan from '@components/cards/CardPlan';
import transition from '@config/transition';
import Product from '@models/Product';
import { apiRebox } from '@services/index';
import { formatMoney } from '@utils/formatters';

import { Plan } from '../../components/newComponents';
import { useScroll } from '../../hooks/useScroll';
import {
  Body,
  Button,
  Container,
  Header,
  RowButtons,
  PlanButtons,
  Separator,
  Title,
  Wrapper,
} from './Styles';

interface ButtonProps {
  text: string;
  icon: IconDefinition;
  name: string;
}

interface FlexProduct {
  [productName: string]: string[];
}

// Define a estrutura para o planFlexItems
interface PlanFlexItems {
  [vehicleType: string]: FlexProduct;
}

const MyDivider: React.FC = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        textAlign: 'center',
        width: '280px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <FontAwesomeIcon
        color={theme.colors.blue.main}
        icon={faCaretUp}
        style={{ marginRight: '10px' }}
        fontSize="20px"
      />
      <Separator />
    </div>
  );
};

export const MyButton: React.FC<ButtonProps> = ({ text, icon, name }) => {
  return (
    <Button name={name}>
      <FontAwesomeIcon
        icon={icon}
        style={{ marginRight: '5px', fontSize: '1.5rem' }}
      />
      {text}
    </Button>
  );
};

const Plans: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [flexProduct, setFlexProduct] = useState<Product>({} as Product);
  const [trackerFlexProduct, setTrackerFlexProduct] = useState<Product[]>([]);
  const [trackers, setTrackers] = useState<Product[]>([]);
  const [vehicleType, setVehicleType] = useState('car');
  const [btnIndex, setBtnIndex] = useState<number>(0);
  const [btnAssist, setBntAssist] = useState<number>(1);
  const buttons = [
    <MyButton key={1} name="car" text="CARRO DE PASSEIO" icon={faCar} />,
    <MyButton
      key={2}
      name="tricycle"
      text="MOTO (até 350cc)"
      icon={faMotorcycle}
    />,
    <MyButton key={3} name="suv" text="UTILITÁRIOS" icon={faTruckPickup} />,
  ];
  const [vehicleTypeItems, setVehicleTypeItems] = useState<string[]>([]);
  const [url, setUrl] = useState<string>('');

  const planItems: { [index: string]: string[] } = {
    'rebox auto#': [
      'Reboque (até 100km)',
      'Troca de pneu',
      'Chaveiro (abertura)',
      'Carga na bateria',
      'Pane seca',
    ],
    'rebox auto smart#': [
      'Reboque (até 100km)',
      'Troca de pneu',
      'Chaveiro (abertura)',
      'Carga na bateria',
      'Pane seca',
    ],
    'rebox auto +#': [
      'Reboque (até 200km)',
      'Troca de pneu',
      'Chaveiro (abertura)',
      'Carga na bateria',
      'Pane seca',
    ],
    'rebox moto#': [
      'Reboque (até 100km)',
      'Troca de pneu',
      'Chaveiro (abertura)',
    ],
    'rebox moto smart#': [
      'Reboque (até 100km)',
      'Troca de pneu',
      'Chaveiro (abertura)',
    ],
    'rebox moto liberty#': [
      'Reboque (até 100km)',
      'Troca de pneu',
      'Chaveiro (abertura)',
    ],
    'rebox utility#': [
      'Reboque (até 100km)',
      'Troca de pneu',
      'Chaveiro (abertura)',
      'Carga na bateria',
      'Pane seca',
    ],
  };

  const planFlexItems: PlanFlexItems = {
    car: {
      'rebox flex': [
        'Reboque (até 100km)',
        'Troca de pneu',
        'Chaveiro (abertura)',
        'Carga na bateria',
        'Pane seca',
      ],
    },
    tricycle: {
      'rebox flex': ['Reboque (até 100km)', 'Troca de pneu', 'Pane seca'],
    },
    suv: {
      'rebox flex': [
        'Reboque (até 100km)',
        'Troca de pneu',
        'Chaveiro (abertura)',
        'Carga na bateria',
        'Pane seca',
      ],
    },
  };

  const buttonsTrackers = [
    <MyButton key={1} name="car" text="CARRO DE PASSEIO" icon={faCar} />,
    <MyButton key={2} name="suv" text="UTILITÁRIOS" icon={faTruckPickup} />,
  ];

  const planTrackers: { [index: string]: string[] } = {
    'rebox tracker': [
      'Rastreador',
      'Bloqueador',
      'Cerca Virtual',
      'Cobertura Nacional',
      'Alertas',
    ],
    'rebox smart tracker': [
      'Reboque (até 200km)',
      'Troca de pneu',
      'Chaveiro (abertura)',
      'Carga na bateria',
      'Pane seca',
      'Rastreador',
      'Bloqueador',
      'Cerca Virtual',
      'Cobertura Nacional',
      'Alertas',
    ],
    'rebox tracker plus': [
      'Reboque (até 400km)',
      'Troca de pneu',
      'Chaveiro (abertura)',
      'Carga na bateria',
      'Pane seca',
      'Rastreador',
      'Bloqueador',
      'Cerca Virtual',
      'Cobertura Nacional',
      'Alertas',
    ],
    'rebox tracker utility': [
      'Rastreador',
      'Bloqueador',
      'Cerca Virtual',
      'Cobertura Nacional',
      'Alertas',
    ],
    'rebox tracker utility+': [
      'Reboque (até 200km)',
      'Troca de pneu',
      'Chaveiro (abertura)',
      'Carga na bateria',
      'Pane seca',
      'Rastreador',
      'Bloqueador',
      'Cerca Virtual',
      'Cobertura Nacional',
      'Alertas',
    ],
  };

  const location = useLocation();
  useScroll(location);

  const handleButtonClick = (idx: number): void => {
    setBtnIndex(idx);
  };

  const getFlexProducts = async () => {
    try {
      const {
        data: { data: response },
      } = await apiRebox.get('/products', {
        params: {
          type: 'plan',
          status: 'active',
          is_flexible: true,
          details: 'all',
        },
      });
      setTrackerFlexProduct(
        response
          .filter(
            (product: Product) =>
              product.name.includes('tracker') || product.name.includes('gps'),
          )
          .sort(
            (a: Product, b: Product) =>
              b.promotional_price - a.promotional_price,
          ),
      );
      setFlexProduct(
        response.find((product: Product) => !product.name.includes('tracker')),
      );
      // setProducts(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getProducts = async () => {
    try {
      const {
        data: { data: response },
      } = await apiRebox.get('/products', {
        params: {
          per_page: '30',
          type: 'plan',
          status: 'active',
          is_flexible: false,
          details: 'all',
        },
      });
      setProducts(
        response
          .filter((product: Product) => !product.name.includes('tracker'))
          .sort(
            (productA: Product, productB: Product) =>
              productA.promotional_price - productB.promotional_price,
          ),
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getTrackers = async () => {
    try {
      const {
        data: { data: response },
      } = await apiRebox.get('/products', {
        params: {
          type: 'plan',
          per_page: 50,
          is_flexible: false,
          details: 'all',
        },
      });
      const responseTrackers = response.filter((tracker: Product) =>
        tracker.name.includes('tracker'),
      );
      setTrackers(responseTrackers);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const sortedTrackers = trackers.sort(
      (tracker: Product, trackerCompare: Product) =>
        tracker.promotional_price - trackerCompare.promotional_price,
    );
    setTrackers(sortedTrackers);
  }, [trackers]);

  useEffect(() => {
    getTrackers();
    getProducts();
    getFlexProducts();
    if (window.location.pathname.split('/').length > 1) {
      const isPromotionPage = window.location.pathname.split('/')[1];
      setUrl(isPromotionPage);
    }
  }, []);

  useEffect(() => {
    if (!url) {
      setVehicleTypeItems(
        planFlexItems[vehicleType.toLowerCase()][flexProduct?.name] || [],
      );
    }
  }, [flexProduct, vehicleType]);

  useEffect(() => {
    localStorage.setItem(
      'classification_vehicle_type',
      JSON.stringify(transition.rebox_vehicles_type[vehicleType.toLowerCase()]),
    );
  }, [vehicleType]);

  return (
    <Container id="planos">
      <Header>
        <Title>Escolha seu plano</Title>
        <MyDivider />
        <RowButtons>
          <div className={btnAssist === 1 ? 'btnActive' : ''}>
            <Button
              onClick={() => {
                setBntAssist(1);
              }}
            >
              ASSISTÊNCIA 24h
            </Button>
          </div>
          {/* <div className={btnAssist === 1 ? '' : 'btnActive'}>
            <Button
              onClick={() => {
                setBntAssist(2);
              }}
            >
              RASTREADOR + ASSISTÊNCIA
            </Button>
          </div> */}
        </RowButtons>

        {btnAssist === 1 && (
          <>
            <PlanButtons>
              <RowButtons>
                {buttons?.map((b, idx) => (
                  <div
                    key={`id-${idx}`}
                    onClick={(event: any): void => {
                      handleButtonClick(idx);
                      setVehicleType(event.target?.name);
                    }}
                    className={btnIndex === idx ? 'btnActive' : ''}
                  >
                    {b}
                  </div>
                ))}
              </RowButtons>
            </PlanButtons>
            <Body>
              {url
                ? products?.map(product => (
                  <Wrapper
                    key={product.id}
                    className={
                      vehicleType?.toUpperCase() ===
                        product.classification.split('_')[1]
                        ? 'planActive'
                        : ''
                    }
                  >
                    <Plan
                      title={product?.name.toUpperCase()}
                      price={formatMoney.fromNumberToPrice(
                        product.promotional_price,
                      )}
                      numberOfYearUtilizations={product.available_uses.toString()}
                      numberOfMonthUtilizations={product.number_calls_allowed_in_seasonality.toString()}
                      vehicleType={product.classification}
                      year="Até 30 anos de fabricação"
                      grace="3 dias úteis após confirmação do pagamento"
                      marginRight="2rem"
                      url={`p=${product.id}&e=lp`}
                      items={product && planItems?.[product?.name]}
                      covered_items={
                        product.classification === 'MOTO_TRICYCLE'
                          ? product.product_items.slice(0, 3)
                          : product.product_items
                      }
                    />
                  </Wrapper>
                ))
                : flexProduct && (
                  <CardPlan
                    title={flexProduct?.name}
                    vehicleType={vehicleType}
                    bestSeller={vehicleType.toLowerCase() === 'car'}
                    year="Até 30 anos de fabricação"
                    grace="30 dias após confirmação do pagamento"
                    numberOfYearUtilizations={flexProduct?.available_uses}
                    price={34.9}
                    id={`p=${flexProduct.id}&e=lp`}
                    items={vehicleTypeItems}
                  />
                )}
            </Body>
          </>
        )}
        {btnAssist === 2 && (
          <>
            <PlanButtons>
              <RowButtons>
                {buttonsTrackers?.map((b, idx) => (
                  <div
                    key={`id-${idx}`}
                    onClick={(event: any): void => {
                      handleButtonClick(idx);
                      setVehicleType(event.target?.name);
                    }}
                    className={btnIndex === idx ? 'btnActive' : ''}
                  >
                    {b}
                  </div>
                ))}
              </RowButtons>
            </PlanButtons>
            <Body>
              {url
                ? trackers?.map(product => (
                  <Wrapper
                    key={product.id}
                    className={
                      product.classification.includes('_')
                        ? vehicleType?.toUpperCase() ===
                          product.classification.split('_')[1]
                          ? 'planActive'
                          : ''
                        : 'planActive'
                    }
                  >
                    <Plan
                      title={product?.name.toUpperCase()}
                      price={formatMoney.fromNumberToPrice(
                        product.promotional_price,
                      )}
                      numberOfYearUtilizations={product.available_uses.toString()}
                      numberOfMonthUtilizations={product.number_calls_allowed_in_seasonality.toString()}
                      vehicleType={product.classification}
                      year="Até 30 anos de fabricação"
                      grace="3 dias úteis após confirmação do pagamento"
                      marginRight="2rem"
                      url={`p=${product.id}&e=lp`}
                      items={planTrackers[product.name]}
                      covered_items={product.product_items}
                    />
                  </Wrapper>
                ))
                : trackerFlexProduct &&
                trackerFlexProduct.map(trackerFlex => (
                  <CardPlan
                    key={trackerFlex.id}
                    title={trackerFlex.name}
                    vehicleType={vehicleType}
                    bestSeller={
                      vehicleType.toLowerCase() === 'car' &&
                      !trackerFlex.name.includes('gps')
                    }
                    year="Até 30 anos de fabricação"
                    grace="30 dias após confirmação do pagamento"
                    numberOfYearUtilizations={trackerFlex.available_uses}
                    price={
                      trackerFlex.name.includes('gps')
                        ? trackerFlex.promotional_price
                        : 64.9
                    }
                    items={
                      trackerFlex.name.includes('gps')
                        ? [
                          'Rastreador',
                          'Bloqueador',
                          'Cerca Virtual',
                          'Cobertura Nacional',
                          'Alertas',
                        ]
                        : [
                          'Reboque (até 100km)',
                          'Troca de pneu',
                          'Chaveiro (abertura)',
                          'Carga na bateria',
                          'Pane seca',
                          'Rastreador',
                          'Bloqueador',
                          'Cerca Virtual',
                          'Cobertura Nacional',
                          'Alertas',
                        ]
                    }
                    id={`p=${trackerFlex.id}&e=lp`}
                  />
                ))}
            </Body>
          </>
        )}
      </Header>
    </Container>
  );
};

export default Plans;

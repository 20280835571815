import React, { useEffect, useState } from 'react';

import { FiEdit } from 'react-icons/fi';
import { IoEllipsisHorizontal, IoTrash } from 'react-icons/io5';

import { ICustomerNotes } from '@components/forms/FormCustomerNotes/typing';
import ModalEditUserNote from '@components/modals/ModalEditUserNote';
import Paragraph from '@components/texts/Paragraph';
import { ConfigStyles } from '@config/index';
import { apiRebox } from '@services/index';
import { formatDate, formatText } from '@utils/formatters';
import { toastify } from '@utils/notifiers';

import { Container, Dropdown, DropdownButton } from './styles';

interface IProps {
  note: ICustomerNotes;
  changeRemoveStatus(status: boolean): void;
}

const Item: React.FC<IProps> = ({ note, changeRemoveStatus }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [employeeName, setEmployeeName] = useState<string>('');

  const getEmployeeUser = async () => {
    const {
      data: { data: userResponse },
    } = await apiRebox.get(`/users/${note.who_created_id}`);
    const [firstName, secondName] = userResponse.name.split(' ');
    const fullName = `${formatText.capitalizedFirstLetter(
      firstName,
    )} ${formatText.capitalizedFirstLetter(secondName)}`;
    setEmployeeName(fullName);
  };

  const handleRemoveNote = async (note_id: string) => {
    try {
      const { data: response } = await apiRebox.delete(
        `/users/note/${note_id}`,
      );
      changeRemoveStatus(true);
      toastify(response.header.message, 'success');
    } catch (error) {
      console.log(error);
      toastify('Houve um erro ao apagar a anotação', 'error');
    }
  };

  useEffect(() => {
    getEmployeeUser();
  }, []);

  return (
    <>
      <Container onClick={() => setIsExpanded(prevState => !prevState)}>
        <Paragraph nameColor="black" textAlign="start" fontSize={12}>
          {formatDate.addMask(note.created_at.split('T')[0])}
        </Paragraph>
        <Paragraph nameColor="black" textAlign="start" fontSize={12}>
          {note.note}
        </Paragraph>
        <Paragraph nameColor="black" textAlign="start" fontSize={12}>
          {employeeName}
        </Paragraph>
        <Paragraph nameColor="black" className="btn-options">
          <IoEllipsisHorizontal
            size={16}
            color={ConfigStyles.rebox.colors.black.main}
            style={{ cursor: 'pointer' }}
          />
        </Paragraph>
        <Dropdown expande={isExpanded}>
          <DropdownButton
            onClick={() => {
              setIsOpenEditModal(prevState => !prevState);
            }}
          >
            <FiEdit
              size={16}
              title="ícone de setas"
              color={ConfigStyles.rebox.colors.black.main}
            />
            <Paragraph
              nameColor="black"
              textAlign="start"
              fontSize={11}
              style={{
                marginLeft: '1vw',
                fontWeight: 500,
              }}
            >
              Editar
            </Paragraph>
          </DropdownButton>

          <DropdownButton
            onClick={() => {
              handleRemoveNote(note.id);
            }}
          >
            <IoTrash
              size={16}
              title="ícone de lixeira"
              color={ConfigStyles.rebox.colors.black.main}
            />
            <Paragraph
              nameColor="black"
              textAlign="start"
              fontSize={11}
              style={{
                marginLeft: '1vw',
                fontWeight: 500,
              }}
            >
              Excluir
            </Paragraph>
          </DropdownButton>
        </Dropdown>
      </Container>
      <ModalEditUserNote
        isOpen={isOpenEditModal}
        change={() => setIsOpenEditModal(prevState => !prevState)}
        note={note}
        changeRefresh={changeRemoveStatus}
      />
    </>
  );
};

export default Item;

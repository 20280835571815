export const ProviderServiceNames: any = {
  reboque_car: {
    name: 'Reboque Carro',
  },
  reboque_moto: {
    name: 'Reboque Moto',
  },
  reboque_suv: {
    name: 'Reboque Suv',
  },
  drypan: {
    name: 'Pane Seca',
  },
  keychain: {
    name: 'Chaveiro',
  },
  battery_recharge: {
    name: 'Recarga de bateria',
  },
  tire_change: {
    name: 'Troca de pneu',
  },
};

import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem 2rem;
  padding-bottom: '0,5px';
`;

export const Title = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({ theme }): string => theme.colors.blue.main};
  text-align: center;
  margin-bottom: 0.5rem;
`;

export const Header = styled.div`
  width: 100%;
  font-size: 1.3rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 1.5rem 0;
  color: ${({ theme }): string => theme.colors.blue.main};
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }): string => theme.colors.white.main};
  padding-top: 1rem;
`;

export const List = styled.li`
  font-size: '16px';
  margin-left: '10px';
`;

export const Button = styled.button`
  font-size: 1rem;
  font-weight: 450;
  display: flex;
  align-items: center;

  line-height: 1px;
  margin-left: 25px;
  background-color: ${({ theme }): string => theme.colors.red.main};
  color: ${({ theme }): string => theme.colors.white.main};
  padding: 0.5rem;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

export const Divisor = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 2vh 0;
`;

import styled from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(244, 245, 249, 0.5);
  color: ${({ theme }) => theme.colors.black.main};
  height: 46px;
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  width: fit-content;
  margin-top: 1rem;

  .datePicker {
    font-weight: 500;
    font-size: ${({ theme }) => theme.fonts.size.paragraph.large}px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.black.main};
    padding: 0px 30px;
    background: transparent;
    border: 0;
    border-radius: 15px;
    width: 100%;
    height: 100%;

    > div {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      input {
        border-style: none;
        width: 100%;
        height: 100%;
        display: flex;
        background: transparent;
      }
    }

    ::placeholder {
      color: ${({ theme }) => theme.colors.black.opacity.average};
      font-size: ${({ theme }) => theme.fonts.size.paragraph.large}px;
    }

    @media (min-width: 768px) {
      font-size: ${({ theme }) => theme.fonts.size.paragraph.large}px;
    }
  }
`;

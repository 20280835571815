import React, { useState } from 'react';

import { IoEllipse, IoEllipsisHorizontal } from 'react-icons/io5';

import Paragraph from '@components/texts/Paragraph';
import { ConfigStyles, ConfigValues } from '@config/index';
import Address from '@models/Address';
import { formatText } from '@utils/formatters';

import { CompositeItem, Container, Dropdown } from './styles';

interface IProps {
  base: Address;
}

const ListBaseItem: React.FC<IProps> = ({ base }) => {
  return (
    <Container>
      <Paragraph nameColor="black" textAlign="start" fontSize={12}>
        {base.state.toUpperCase()}
      </Paragraph>
      <Paragraph nameColor="black" textAlign="start" fontSize={12}>
        {base.city.toUpperCase()}
      </Paragraph>
      <Paragraph nameColor="black" textAlign="start" fontSize={12}>
        {base.neighborhood.toUpperCase()}
      </Paragraph>
      <Paragraph nameColor="black" textAlign="start" fontSize={12}>
        {base.street.toUpperCase()}
      </Paragraph>
      <Paragraph nameColor="black" textAlign="start" fontSize={12}>
        {base.number}
      </Paragraph>
      <Paragraph nameColor="black" textAlign="start" fontSize={12}>
        {base.complement ? base.complement.toUpperCase() : 'Não possui.'}
      </Paragraph>
      <Paragraph nameColor="black" textAlign="start" fontSize={12}>
        {base.zip_code}
      </Paragraph>
    </Container>
  );
};

export default ListBaseItem;

// ./src/services/apis/apiRebox.ts
import axios from 'axios';

import { ConfigBase, ConfigAuth } from '@config/index';

const apiZapinho = axios.create({
  baseURL: ConfigBase.zapinho.baseUrls.users,
  headers: {
    'access-token': `${ConfigAuth.zapinho.access_token}`,
  },
});

export default apiZapinho;

import { useEffect, useState } from 'react';

import { IoLogoWhatsapp } from 'react-icons/io5';
import { Link, useLocation } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';

import { ImageAppleStore, ImageGooglePlayStore } from '@assets/images';
import { CardAppPlatforms } from '@components/index';
import {
  ConfigBase,
  ConfigStorage,
  ConfigStyles,
  ConfigValues,
} from '@config/index';

import { Footer } from '../../../components/newComponents';
import SubtitleMain from '../../../components/texts/SubtitleMain/index';
import {
  AboutUs,
  Differentials,
  Faq,
  Header,
  HowItWorks,
  Plans,
  Testimonials,
} from '../../../containers';
import { Container } from './Styles';

import { Buttons } from '../Plan/ProductItem/styles';

const Home: React.FC = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);

  // Parâmetros na URL
  const [codeOfWhoIsReferring, setCodeOfWhoIsReferring] = useState<
    string | null
  >(useQuery().get('i'));
  const [environment, setEnvironment] = useState<string>(
    useQuery().get('e') || ConfigValues.rebox.user.environment.landingPage,
  );

  useEffect(() => {
    localStorage.removeItem('is_tracker_plan');
    localStorage.removeItem('selected-hour');
    localStorage.removeItem('selected-date');
    sessionStorage.setItem(ConfigStorage.REBOX_USER_ENVIRONMENT, environment);
    if (codeOfWhoIsReferring) {
      sessionStorage.setItem(
        ConfigStorage.REBOX_USER_CODE_WHO_INDICATED,
        codeOfWhoIsReferring,
      );
    }
  }, []);

  return (
    <Container>
      <ScrollToTop smooth style={{ position: 'fixed', top: '16px' }} />
      <Header />
      <Testimonials />
      <AboutUs />
      <HowItWorks />
      <Plans />
      <SubtitleMain style={{ marginBottom: '1em' }}>
        Baixe o nosso App
      </SubtitleMain>
      <Buttons>
        <CardAppPlatforms.AppPlatform
          linkApp={ConfigBase.rebox.playStore.App}
          namePlatform="Android"
          LogoSvg={ImageGooglePlayStore}
        />
        <CardAppPlatforms.AppPlatform
          linkApp={ConfigBase.rebox.appStore.App}
          namePlatform="IOS"
          LogoSvg={ImageAppleStore}
        />
      </Buttons>
      <Differentials />
      <Faq />
      <Footer />
      <Link
        style={{
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          width: '70px',
          height: '70px',
          justifyContent: 'center',
          bottom: 20,
          borderRadius: '50%',
          right: 20,
          backgroundColor: ConfigStyles.rebox.colors.white.main,
          boxShadow: '0px 0px 5px 5px #00000020',
        }}
        to={{
          pathname: `${ConfigBase.whatsapp.baseUrls.webApi}/send?phone=${
            ConfigBase.rebox.whatsapp.commercial
          }&text=${'Olá! Pode me ajudar?'}`,
        }}
        target="_blank"
      >
        <IoLogoWhatsapp
          size={50}
          color={ConfigStyles.rebox.colors.greenEmerald.main}
        />
      </Link>
    </Container>
  );
};

export default Home;

import { useLocation, useHistory } from 'react-router-dom';

import images from '../../constants/images';
import { useScroll } from '../../hooks/useScroll';
import {
  Body,
  Button,
  Container,
  Desc,
  Image,
  ImageWrapper,
  ImageWrapperColumn,
  Title,
  Wrapper,
} from './Styles';

const AboutUs: React.FC = () => {
  const { push } = useHistory();
  const location = useLocation();
  useScroll(location);

  return (
    <Container id="quemSomos">
      <Wrapper>
        <div>
          <Title>O que fazemos?</Title>
          <Desc style={{ marginBottom: '1rem' }}>
            Conheça nossos planos de assistência veicular 24h
          </Desc>
          <Desc>Pague apenas alguns centavos por dia e fique tranquilo!</Desc>
          <Desc style={{ marginBottom: '1.5rem' }}>
            CONTRATE AGORA e CANCELE quando quiser.
          </Desc>
          <Desc>
            Na Rebox não tem FIDELIDADE, caso você não use nenhuma assistência.
          </Desc>
          <br />
          <Body>
            <ImageWrapper>
              <ImageWrapperColumn>
                <ImageWrapper>
                  <Image src={images.reboque} loading="lazy" />
                </ImageWrapper>
                <ImageWrapper>
                  <Image src={images.chaveiro} loading="lazy" />
                </ImageWrapper>
              </ImageWrapperColumn>
            </ImageWrapper>
            <ImageWrapper>
              <ImageWrapperColumn>
                <ImageWrapper>
                  <Image src={images.combustivel} loading="lazy" />
                </ImageWrapper>
                <ImageWrapper>
                  <Image src={images.mecanico} loading="lazy" />
                </ImageWrapper>
              </ImageWrapperColumn>
            </ImageWrapper>
            <ImageWrapper>
              <ImageWrapperColumn>
                <ImageWrapper>
                  <Image src={images.pneu} loading="lazy" />
                </ImageWrapper>
                <ImageWrapper>
                  <Image src={images.bateria} loading="lazy" />
                </ImageWrapper>
              </ImageWrapperColumn>
            </ImageWrapper>
          </Body>
        </div>
        <Button onClick={(): void => push('/#planos')}>Saiba mais</Button>
      </Wrapper>
    </Container>
  );
};

export default AboutUs;

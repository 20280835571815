import React, { useState } from 'react';

import { PlanModal } from '@components/newComponents';
import transition from '@config/transition';
import { formatMoney } from '@utils/formatters';

import Item from './Item';

import {
  Container,
  Description,
  KnowMoreButton,
  Price,
  Title,
  Wrapper,
} from './styles';

interface IProps {
  title: string;
  vehicleType: string;
  price: number;
  numberOfYearUtilizations: number;
  items: string[];
  grace: string;
  bestSeller: boolean;
  year: string;
  id: string;
}

const CardPlan: React.FC<IProps> = ({
  title,
  vehicleType,
  price,
  grace,
  numberOfYearUtilizations,
  items,
  bestSeller,
  year,
  id,
}) => {
  const [showPlanModal, setShowPlanModal] = useState<boolean>(false);

  const handleShowPlanModal = (): void => {
    setShowPlanModal(true);
  };

  const handleClosePlanModal = (): void => {
    setShowPlanModal(false);
  };

  return (
    <Container bestSeller={bestSeller}>
      <Title>
        {title?.toUpperCase()}
        {bestSeller && (
          <div className="ribbonPrice">
            <div className="ribbonInner">Mais vendido</div>
          </div>
        )}
      </Title>
      <Price>A partir de {formatMoney.fromNumberToPrice(price)} por mês</Price>
      <Wrapper>
        <Description>Aviso: Proibido para veículo quebrado</Description>
        <Description>
          {numberOfYearUtilizations} Utilizações por ano
        </Description>
        <Description style={{ fontWeight: 900 }}>Cobertura:</Description>
        {items.map(item => (
          <Item
            key={item}
            numberOfYearUtilizations={numberOfYearUtilizations}
            text={item}
          />
        ))}
      </Wrapper>
      <KnowMoreButton onClick={handleShowPlanModal}>
        Clique e saiba mais
      </KnowMoreButton>
      <PlanModal
        show={showPlanModal}
        onClose={handleClosePlanModal}
        title={title}
        numberOfYearUtilizations={numberOfYearUtilizations?.toString() ?? 0}
        numberOfMonthUtilizations={'2'}
        vehicleType={vehicleType.toLowerCase()}
        year={year}
        grace={grace}
        price={`A partir de ${formatMoney.fromNumberToPrice(price)}`}
        items={[]}
        url={`https://rebox.com.br/checkout?${id}`}
      />
    </Container>
  );
};

export default CardPlan;

import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import ButtonMain from '@components/buttons/ButtonMain';
import InputText from '@components/inputs/InputText';
import ListNotes from '@components/lists/ListNotes';
import Paragraph from '@components/texts/Paragraph';
import SubtitleSecondary from '@components/texts/SubtitleSecondary';
import { ConfigStorage } from '@config/index';
import { apiRebox } from '@services/index';
import { toastify } from '@utils/notifiers';

import { ICustomerNotes, IUrlParams, viewType } from './typing';

import {
  Container,
  FormCustomerNote,
  Sections,
  SectionsGroup,
  SectionsItem,
  SubTabLabels,
  SubTabLabelsButton,
  SubTabLabelsDivisor,
  SubTabs,
} from './styles';

const FormCustomerNotes: React.FC = () => {
  const params: IUrlParams = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isView, setIsView] = useState<viewType>('ADD');
  const [whoCreatedId, setWhoCreatedId] = useState<string>('');
  const [subTab, setSubTab] = useState<number>(1);
  const [notes, setNotes] = useState<ICustomerNotes[]>([]);
  const [noteText, setNoteText] = useState<string>('');
  const [refresh, setRefresh] = useState<boolean>(false);

  const handleAddNotes = async () => {
    try {
      setIsLoading(true);
      const { data: notesResponse } = await apiRebox.post(
        `/users/${params.id}/note`,
        {
          note: noteText,
          who_created_id: whoCreatedId,
        },
      );
      const { header } = notesResponse;
      setIsLoading(false);
      toastify(header.message, 'success');
    } catch (error: any) {
      toastify('Houve um erro ao criar a anotação.', 'error');
    }
  };

  const getNotes = async () => {
    try {
      const {
        data: { data: notesResponse },
      } = await apiRebox.get(`/users/note?users_id=${params.id}`);
      setNotes(notesResponse);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    const employeeUser = localStorage.getItem(
      ConfigStorage.REBOX_SESSION_LOGGED_IN_USER,
    );
    const id = employeeUser ? JSON.parse(employeeUser).id : '';
    setWhoCreatedId(id);
  }, []);

  useEffect(() => {
    getNotes();
    setRefresh(false);
  }, [refresh, isView]);

  return (
    <Container>
      <Sections>
        <SubTabs>
          <SubTabLabels>
            <SubTabLabelsButton
              isActive={subTab === 1}
              onClick={() => {
                setSubTab(1);
                setIsView('ADD');
              }}
            >
              <Paragraph>Adicionar</Paragraph>
            </SubTabLabelsButton>
            <SubTabLabelsDivisor />
            <SubTabLabelsButton
              isActive={subTab === 2}
              onClick={() => {
                setSubTab(2);
                setIsView('VIEW');
              }}
            >
              <Paragraph>Visualizar</Paragraph>
            </SubTabLabelsButton>
          </SubTabLabels>
        </SubTabs>
        <SectionsGroup>
          {isView === 'ADD' ? (
            <SectionsItem>
              <FormCustomerNote ref={() => []} onSubmit={() => []}>
                <SubtitleSecondary
                  textAlign="start"
                  nameColor="black"
                  fontSize={14}
                >
                  Nota
                </SubtitleSecondary>
                <Paragraph
                  nameColor="black"
                  textAlign="start"
                  opacity={0.5}
                  fontSize={13}
                  style={{ marginBottom: '2vh' }}
                >
                  Adicione uma nota sobre o usuário
                </Paragraph>
                <InputText
                  style={{ width: '30vw' }}
                  name="noteText"
                  placeholder="Insira a nota aqui"
                  onChange={({ target }) => setNoteText(target.value)}
                />
                <ButtonMain
                  type="submit"
                  style={{ width: 200, marginTop: '2vh' }}
                  loading={isLoading}
                  onClick={handleAddNotes}
                >
                  Adicionar
                </ButtonMain>
              </FormCustomerNote>
            </SectionsItem>
          ) : (
            <ListNotes notes={notes} changeRemoveStatus={setRefresh} />
          )}
        </SectionsGroup>
      </Sections>
    </Container>
  );
};

export default FormCustomerNotes;

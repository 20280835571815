import * as Yup from 'yup';

import { ConfigValues } from '@config/index';
import { formatCNPJ, formatCPF, formatText } from '@utils/formatters';
import { validatorCnpj, validatorCpf } from '@utils/validators';

export const schemaProvider = Yup.object().shape({
  name: Yup.string().required('Por favor, informe o nome completo do cliente.'),
  person_type: Yup.string().required(
    'Por favor, informe o tipo de pessoa (física/jurídica)',
  ),
  cpf_cnpj: Yup.string().when('person_type', {
    is: value => value === ConfigValues.rebox.user.person_type.physical_person,
    then: Yup.string()
      .required('Por favor, informe o CPF do prestador.')
      .test('cpf-valid', 'Por favor, informe um CPF válido.', value =>
        validatorCpf.check(formatCPF.removeMask(value || '')),
      ),
    otherwise: Yup.string()
      .required('Por favor, informe o CNPJ do prestador.')
      .test(
        'cnpj-valid',
        'Por favor, informe um CNPJ válido.',
        value => !validatorCnpj.check(formatCNPJ.removeMask(value || '')),
      ),
  }),
  email: Yup.string()
    .required('Por favor, informe o e-mail do prestador.')
    .email('Por favor, informe um endereço de e-mail válido'),
  cellphone: Yup.string()
    .required('Por favor, informe o celular e/ou whatsapp do prestador')
    .test(
      'cellphone-valid',
      'Por favor, informe um celular e/ou whatsapp válido.',
      value => formatText.removeAllNonDigits(value || '').length === 13,
    ),
  status: Yup.string().required(
    'Por favor, informe a situação atual do prestador.',
  ),
});

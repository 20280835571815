// ./src/components/inputs/InputCheckBox/index.tsx
import React, { InputHTMLAttributes } from 'react';

import { Container, Label, CheckBox } from './styles';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label: any;
  id?: string;
  onChange(e?: any): void;
  defaultChecked: boolean;
  name?: string;
  disabled?: boolean;
  checked?: boolean;
}

const InputCheckBox: React.FC<IProps> = ({
  label,
  onChange,
  id,
  defaultChecked,
  name,
  disabled,
  checked,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <Label
        label={label}
        control={
          <CheckBox
            color="primary"
            size="small"
            name={name}
            id={id}
            onChange={onChange}
            defaultChecked={defaultChecked}
            disabled={disabled}
            checked={checked}
          />
        }
      />
    </Container>
  );
};

export default InputCheckBox;

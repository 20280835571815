// ./src/utils/validators/validatorPhone.ts
const check = (phone: string): boolean => {
  // texto@texto.com === true
  // texto@texto === false
  // texto.com === false
  // texto === false
  const regex = /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/;
  return regex.test(phone);
};

export default {
  check,
};

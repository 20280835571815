import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  margin: 0 auto;

  width: 100%;

  padding: 0 20px;

  @media (min-width: 1024px) {
    padding: 0;
  }

  @media (max-width: 767.9px) {
    padding: 0 5vw;
  }

  @media (max-width: 479.9px) {
    padding: 0 5vw;
  }
`;

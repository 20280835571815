import React, { useState } from 'react';

import Paragraph from '@components/texts/Paragraph';
import ProviderServices from '@models/ProviderServices';
import { formatText, formatMoney } from '@utils/formatters';

import { ProviderServiceNames } from './data';

import { Container } from './styles';

interface IProps {
  providerService: ProviderServices;
  is24Hour: boolean | undefined;
}

const ListProviderServicesItem: React.FC<IProps> = ({
  providerService,
  is24Hour,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <Container>
      <Paragraph nameColor="black" textAlign="start" fontSize={12}>
        {ProviderServiceNames[providerService.name].name}
      </Paragraph>
      <Paragraph nameColor="black" textAlign="start" fontSize={12}>
        {is24Hour ? '24 horas' : 'Horário fixo'}
      </Paragraph>
      {!is24Hour && (
        <>
          <Paragraph nameColor="black" textAlign="start" fontSize={12}>
            {providerService.util_day}
          </Paragraph>
          <Paragraph nameColor="black" textAlign="start" fontSize={12}>
            {providerService.saturday}
          </Paragraph>
          <Paragraph nameColor="black" textAlign="start" fontSize={12}>
            {providerService.sunday}
          </Paragraph>
        </>
      )}
      <Paragraph nameColor="black" textAlign="start" fontSize={12}>
        {formatMoney.fromNumberToPrice(providerService.exit_km)}
      </Paragraph>
      <Paragraph nameColor="black" textAlign="start" fontSize={12}>
        {formatMoney.fromNumberToPrice(providerService.over_km)}
      </Paragraph>
      <Paragraph nameColor="black" textAlign="start" fontSize={12}>
        {formatMoney.fromNumberToPrice(providerService.stopped_hour)}
      </Paragraph>
      <Paragraph nameColor="black" textAlign="start" fontSize={12}>
        {formatMoney.fromNumberToPrice(providerService.worked_hour)}
      </Paragraph>
    </Container>
  );
};

export default ListProviderServicesItem;

// ./src/pages/privates/Contract/New/Step/index.tsx
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FormHandles } from '@unform/core';
import { IoCart, IoPersonCircleOutline } from 'react-icons/io5';
import { useLocation, useHistory, Link } from 'react-router-dom';
import * as Yup from 'yup';

import {
  SubtitleSecondary,
  Paragraph,
  ButtonMain,
  ButtonDefault,
  InputSelect,
  InputMask,
  InputText,
  AlertSimpleCustom,
  ModalUserAuthenticate,
  ButtonOutline,
} from '@components/index';
import {
  ConfigBase,
  ConfigLabel,
  ConfigRoutes,
  ConfigStorage,
  ConfigStyles,
  ConfigTransition,
  ConfigValues,
} from '@config/index';
import Product from '@models/Product';
import User from '@models/User';
import {
  apiRebox,
  newContractStorageService,
  sessionStorageService,
} from '@services/index';
import { getValidationErrors } from '@utils/errors';
import {
  formatCellphone,
  formatCNPJ,
  formatCPF,
  formatDate,
  formatMoney,
  formatText,
} from '@utils/formatters';
import { hotToast, toastify } from '@utils/notifiers';

import FormPersonalData from './FormPersonalData';
import { IStepsSale } from './typing';

import {
  Container,
  SectionsProduct,
  SectionsProductTitle,
  SectionsProductGroup,
  SectionsProductItem,
  SectionsIdentification,
  SectionsIdentificationGroup,
  SectionsIdentificationTitle,
  SectionsIdentificationResume,
  SectionsIdentificationResumeGroup,
  SectionsIdentificationResumeImage,
  SectionsIdentificationResumeImageAvatar,
  SectionsIdentificationResumeText,
  Buttons,
} from './styles';

interface IProps {
  myStep: number;
  currentStep: number;
  changeStep(newStep: number, willChangeHistory?: boolean): void;
  handleAuthLogin(): void;
}

const StepCustomer: React.FC<IProps> = ({
  myStep,
  currentStep,
  changeStep,
  handleAuthLogin,
}) => {
  const { push } = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const params = useQuery();
  const formValidationRef = useRef<FormHandles>(null);

  const [loadingProduct, setLoadingProduct] = useState<boolean>(false);
  const [product, setProduct] = useState<Product[]>();
  const [fieldTypeChosen, setFieldTypeChosen] = useState<string>(
    newContractStorageService.getCustomer().field_type ||
      ConfigValues.rebox.default.outhers.sales_new.field_type.user_cpf,
  );
  const [customerStorage, setCustomerStorage] = useState<IStepsSale | null>(
    newContractStorageService.getCustomer(),
  );
  const [customerLoggedIn, setCustomerLoggedIn] = useState<User | null>(
    sessionStorageService.getUser(),
  );
  const [environment, setEnvironment] = useState<string>(() => {
    const {
      appCustomer,
      attendanceWhatsapp,
      landingPage,
      marketing,
      operacional,
      discountCupom,
      mkt_place,
      thirty_days_cupom,
      indication,
      blocker,
    } = ConfigValues.rebox.user.environment;
    const cutOff: any = {
      lp: landingPage,
      mk: marketing,
      op: operacional,
      aw: attendanceWhatsapp,
      ac: appCustomer,
      i: indication,
      mkt: mkt_place,
      dc: discountCupom,
      d30: thirty_days_cupom,
      bl: blocker,
    };

    const env = params.get('e') || 'lp';

    return cutOff[env.toLowerCase()];
  });

  const [isTrackerPlan, setIsTrackerPlan] = useState<boolean>(false);

  const [whoGaveDiscountId, setWhoGaveDiscountId] = useState<string>(
    params.get('wgd') || '',
  );
  const [productSelected, setProductSelected] = useState<string>(
    params.get('p') || '',
  );
  const [sessionOpenedId, setSessionOpenedId] = useState<string>(
    params.get('s') || '',
  );
  const [
    discountCouponsSelected,
    setDiscountCouponsSelected,
  ] = useState<string>(params.get('c') || '');

  const [modalSignIn, setModalSignIn] = useState<boolean>(false);

  const changeModalSignIn = () => {
    setModalSignIn(prevState => !prevState);
  };

  const getProduct = useCallback(async () => {
    let idHotToast = null;
    try {
      setLoadingProduct(prevState => !prevState);
      let productSave: Product = {} as Product;
      const productStorage = newContractStorageService.getProduct();
      let productId =
        productSelected || productStorage[productStorage.length - 1]?.id;

      // Se for o código do produto
      if (productId?.length === 8) {
        const { data: responseProduct } = await apiRebox.get(
          `/products?code=${productId}`,
        );
        const [firstProduct] = responseProduct.data;
        if (firstProduct) {
          productSave = firstProduct;
          productId = firstProduct.id;
        }
      }

      if (productId) {
        const cartSale = newContractStorageService.getCart();
        if (
          !cartSale ||
          (cartSale && cartSale[cartSale.length - 1]?.id !== productId)
        ) {
          idHotToast = hotToast(
            'Adicionando produto no carrinho...',
            'loading',
          );
          if (!productSave.id) {
            const { data: responseProduct } = await apiRebox.get(
              `/products/${productId}`,
            );
            productSave = responseProduct.data;
          }
          if (productStorage.length === 0) {
            newContractStorageService.updateProduct({
              id: productId,
              field_type:
                productStorage.length === 0
                  ? 'PRODUCT_ID'
                  : productStorage[0].field_type,
              query: productSave.classification,
            });
          }
          localStorage.setItem(
            'is_tracker_plan',
            JSON.stringify(productSave.name.includes('tracker')),
          );
          localStorage.setItem(
            'is_flexible_product',
            JSON.stringify(productSave.is_flexible),
          );
          newContractStorageService.updateCart(productSave);
          toastify('Produto adicionado no carrinho.', 'success');
        }
        // else {
        //   console.log('Entrou aqui?');
        //   console.log(cartSale);
        //   console.log(productSave);

        //   // productSave = cartSale;
        // }

        setProduct(newContractStorageService.getCart());
      }
    } catch (error) {
      console.log(error);
      toastify('Não foi possível inserir produto no carrinho.', 'error');
    } finally {
      hotToast(idHotToast, 'dismiss');
      setLoadingProduct(prevState => !prevState);
    }
  }, [productSelected]);

  const getDiscountCoupons = useCallback(async () => {
    try {
      if (discountCouponsSelected) {
        const { data: responseDiscountCoupon } = await apiRebox.get(
          `/coupons?code=${discountCouponsSelected}`,
        );
        const [firstDiscountCoupon] = responseDiscountCoupon.data;
        newContractStorageService.updateDiscountCoupons(firstDiscountCoupon);
      }
    } catch (error) {
      console.log('Houve um error ao tentar validar o cupom de desconto');
    }
  }, [discountCouponsSelected]);

  const handleLogOut = useCallback(async () => {
    const idHotToast = hotToast('Saindo...', 'loading');
    try {
      await apiRebox.put(`/sessions/${sessionStorageService.getId()}`, {});

      sessionStorageService.clean();
      newContractStorageService.cleanMany('customer');
      setCustomerStorage(null);
      handleAuthLogin();
    } catch (error) {
      toastify('Sinto muito, houve um erro ao tentar fazer o logout.', 'error');
    } finally {
      hotToast(idHotToast, 'dismiss');
    }
  }, [customerLoggedIn]);

  const handleChooseProduct = useCallback(() => {
    push(ConfigRoutes.rebox.publics.plan.path);
  }, []);

  const customerSessionLauncher = useCallback(async () => {
    try {
      let userSessionOpened: User | null = null;
      if (sessionOpenedId) {
        const { data: responseSession } = await apiRebox.get(
          `/sessions/${sessionOpenedId}`,
        );
        const session = responseSession.data;
        sessionStorageService.updateRemember();
        sessionStorageService.update({
          sessions_id: session.id,
          token: session.token,
          user: session.user,
        });
        userSessionOpened = session.user;
        setCustomerLoggedIn(userSessionOpened);
      } else {
        userSessionOpened = sessionStorageService.getUser();
      }

      if (userSessionOpened) {
        const dataStorage: IStepsSale = {
          id: userSessionOpened.id || '',
          field_type:
            ConfigValues.rebox.default.outhers.checkout.stepCustomer.field_type
              .signIn,
          query: userSessionOpened.email,
        };
        newContractStorageService.updateCustomer(dataStorage);
        setCustomerStorage(dataStorage);
      }
    } catch (error) {}
  }, [sessionOpenedId]);

  useEffect(() => {
    const customerExist = newContractStorageService.getCustomer();
    if (customerExist.field_type !== fieldTypeChosen) {
      formValidationRef.current?.setData({
        query: '',
      });
    }
  }, [fieldTypeChosen]);

  useEffect(() => {
    sessionStorage.setItem(ConfigStorage.REBOX_USER_ENVIRONMENT, environment);
    sessionStorage.setItem(
      ConfigStorage.REBOX_USER_WHO_GAVE_DISCOUNT_ID,
      whoGaveDiscountId,
    );
    const isTrackerPlanSaved = localStorage.getItem('is_tracker_plan');
    if (isTrackerPlanSaved) setIsTrackerPlan(JSON.parse(isTrackerPlanSaved));
    getProduct();
    getDiscountCoupons();
    customerSessionLauncher();
  }, []);

  useEffect(() => {
    if (product && product.length > 0) {
      for (const produc of product) {
        if (produc?.name?.includes('tracker')) {
          window?.dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
              items: [
                ...product.map(prod => ({
                  item_name: prod.name,
                  item_id: prod.id,
                  price: prod.current_price,
                  item_brand: 'REBOX',
                  item_category: prod.category,
                  item_variant: prod.type,
                  quantity: 1,
                })),
              ],
            },
          });

          window?.dataLayer.push({ ecommerce: null });
          window?.dataLayer.push({
            event: 'begin_checkout',
            ecommerce: {
              items: [
                ...product.map(prod => ({
                  item_name: prod.name,
                  item_id: prod.id,
                  price: prod.current_price,
                  item_brand: 'REBOX',
                  item_category: prod.category,
                  item_variant: prod.type,
                  quantity: 1,
                })),
              ],
            },
          });
        }
      }
    }
  }, [product]);

  return (
    <Container>
      <SectionsProduct>
        <SectionsProductTitle>
          <IoCart
            size={24}
            title="ícone de carrinho de compra"
            color={ConfigStyles.rebox.colors.blue.main}
          />
          <SubtitleSecondary textAlign="start">Seu carrinho</SubtitleSecondary>
        </SectionsProductTitle>
        {loadingProduct ? (
          <Paragraph
            nameColor="black"
            textAlign="start"
            fontSize={ConfigStyles.rebox.fonts.size.paragraph.large}
            fontWeight={500}
          >
            Por favor, aguarde...
          </Paragraph>
        ) : (
          <>
            {product?.length !== 0 ? (
              <SectionsProductGroup>
                {product?.map((productCart: Product) => (
                  <>
                    <SectionsProductItem>
                      <Paragraph
                        nameColor="black"
                        fontSize={ConfigStyles.rebox.fonts.size.paragraph.big}
                        fontWeight={600}
                        textAlign="start"
                      >
                        {`${productCart.name}`.toUpperCase()}
                      </Paragraph>
                      {productCart?.current_price > 0 &&
                        (environment.toLowerCase() === 'marketing' &&
                        productCart.name.toLowerCase().includes('tracker') ? (
                          <Paragraph
                            nameColor="black"
                            fontSize={
                              ConfigStyles.rebox.fonts.size.paragraph.big
                            }
                            fontWeight={500}
                            textAlign="end"
                          >
                            {formatMoney.fromNumberToPrice(
                              productCart.promotional_price - 5 ||
                                productCart.current_price - 5 ||
                                0 - 5,
                            )}{' '}
                            / mês
                          </Paragraph>
                        ) : (
                          <Paragraph
                            nameColor="black"
                            fontSize={
                              ConfigStyles.rebox.fonts.size.paragraph.big
                            }
                            fontWeight={500}
                            textAlign="end"
                          >
                            {formatMoney.fromNumberToPrice(
                              productCart.promotional_price ||
                                productCart.current_price ||
                                0,
                            )}{' '}
                            / mês
                          </Paragraph>
                        ))}
                    </SectionsProductItem>
                    <Paragraph
                      nameColor="black"
                      fontSize={ConfigStyles.rebox.fonts.size.paragraph.big}
                      fontWeight={400}
                      textAlign="start"
                    >
                      {productCart.description
                        ? formatText.capitalizedFirstLetter(
                            productCart.description,
                          )
                        : `Direito a ${productCart.available_uses} utilizações durante um ano.`}
                    </Paragraph>
                  </>
                ))}
              </SectionsProductGroup>
            ) : (
              <Paragraph
                nameColor="black"
                textAlign="start"
                fontSize={ConfigStyles.rebox.fonts.size.paragraph.large}
                fontWeight={500}
              >
                Seu carrinho está vazio
              </Paragraph>
            )}
          </>
        )}
      </SectionsProduct>
      {product?.length !== 0 ? (
        <>
          <SectionsIdentification>
            {customerStorage?.id ? (
              <>
                {customerStorage?.field_type ===
                  ConfigValues.rebox.default.outhers.checkout.stepCustomer
                    .field_type.signIn && (
                  <SectionsIdentificationResume>
                    <SectionsIdentificationResumeGroup>
                      <SectionsIdentificationResumeImage>
                        {customerLoggedIn?.image_url ? (
                          <SectionsIdentificationResumeImageAvatar
                            src={customerLoggedIn?.image_url}
                          />
                        ) : (
                          <IoPersonCircleOutline
                            color={ConfigStyles.rebox.colors.black.main}
                            size={80}
                          />
                        )}
                      </SectionsIdentificationResumeImage>

                      <SectionsIdentificationResumeText>
                        <Paragraph
                          nameColor="black"
                          textAlign="start"
                          fontWeight={600}
                        >
                          {formatText.fullCapitalized(
                            customerLoggedIn?.name || '',
                          )}
                        </Paragraph>
                        <Paragraph nameColor="black" textAlign="start">
                          {customerLoggedIn?.email}
                        </Paragraph>
                        <Paragraph
                          nameColor="greenEmerald"
                          textAlign="start"
                          fontWeight={600}
                        >
                          Logado
                        </Paragraph>
                      </SectionsIdentificationResumeText>
                    </SectionsIdentificationResumeGroup>
                    <ButtonOutline
                      onClick={handleLogOut}
                      style={{ maxWidth: '100px' }}
                    >
                      Sair
                    </ButtonOutline>
                  </SectionsIdentificationResume>
                )}
                {customerStorage?.field_type ===
                  ConfigValues.rebox.default.outhers.checkout.stepCustomer
                    .field_type.register && (
                  <FormPersonalData
                    forNewSale={{
                      advanceStep: () => {
                        changeStep(currentStep + 1);
                      },
                    }}
                  />
                )}
              </>
            ) : (
              <>
                <Buttons>
                  <Link
                    to={{
                      pathname: `${
                        ConfigBase.whatsapp.baseUrls.webApi
                      }/send?phone=${
                        ConfigBase.rebox.whatsapp.commercial
                      }&text=${'Sou cliente mas quero contratar um novo plano'}`,
                    }}
                    target="_blank"
                  >
                    <ButtonMain>Já é Cliente? Clique aqui!</ButtonMain>
                  </Link>
                </Buttons>
                <SectionsIdentificationTitle>
                  <SubtitleSecondary
                    textAlign="start"
                    nameColor="black"
                    fontWeight={600}
                  >
                    Cadastre-se
                  </SubtitleSecondary>
                  <Paragraph
                    textAlign="start"
                    nameColor="black"
                    opacity={0.8}
                    fontWeight={500}
                  ></Paragraph>
                </SectionsIdentificationTitle>

                <FormPersonalData
                  forNewSale={{
                    advanceStep: () => {
                      changeStep(currentStep + 1);
                    },
                  }}
                />
              </>
            )}
          </SectionsIdentification>

          <Buttons>
            {customerStorage?.field_type === 'SIGN_IN' && (
              <ButtonMain
                onClick={() => changeStep(currentStep + 1)}
                style={{ marginRight: '10px', maxWidth: 200 }}
              >
                Continuar
              </ButtonMain>
            )}

            <ButtonDefault
              onClick={handleChooseProduct}
              style={{ maxWidth: 200 }}
            >
              Voltar
            </ButtonDefault>
          </Buttons>

          <ModalUserAuthenticate
            isOpen={modalSignIn}
            change={changeModalSignIn}
            forNewSale={{
              advanceStep: () => {
                changeStep(currentStep + 1);
                handleAuthLogin();
              },
            }}
          />
        </>
      ) : (
        <>
          <AlertSimpleCustom
            text={`Para continuar em sua compra, por favor escolha um de nossos produtos.`}
            type="warning"
          />
          <ButtonMain onClick={handleChooseProduct} style={{ maxWidth: 250 }}>
            Ir escolher produto
          </ButtonMain>
        </>
      )}
    </Container>
  );
};

export default StepCustomer;

// src/components/forms/FormCommunication/styles.ts
import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white.main};
  text-align: center;
  margin: 3vh 0;
  width: 100%;
  padding: 6vh 4vw;
  border-radius: 10px;
`;

export const DividingLine = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 4vh 0;
`;

export const FormNotice = styled(Form)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const Sections = styled.div`
  width: 100%;
`;

export const SectionsGroup = styled.div`
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  /* column-gap: 10vw; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2vh 0;
  height: 100%;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

export const SectionsItem = styled.div`
  width: 100%;
  /* margin-bottom: 20px; */

  @media (max-width: 768px) {
    padding: 2vh 0;
  }
`;

export const UserContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`;

export const InputRadio = styled.input`
  background-color: tomato;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* & + div {
    margin-top: 20px;
  } */

  h4 {
    margin: 0;
    padding: 0;
    word-break: break-word;
    line-height: 30px;
    font-weight: 600;
    text-align: start;
    font-size: 14px;
    color: #1d1d1d;
    opacity: 1;
  }
`;

export const SendFileContainer = styled.label`
  display: flex;
  /* margin-top: 20px; */
  gap: 20px;
  align-items: center;
  cursor: pointer;

  span {
    border: 1.5px solid blue;
    width: 175px;
    padding: 0.7rem;
    border-radius: 0.25rem;
    max-width: 175px;
    color: blue;
    transition: 0.2s;

    &:hover {
      border: 1.5px solid #333;
      color: #333;
    }
  }

  input {
    display: none;
  }
`;

interface FieldSetProps {
  spanColor?: string;
}

export const TextArea = styled.fieldset<FieldSetProps>`
  border: none;
  position: relative;
  border: 1px solid #bfbfbf;
  background-color: #ededed;
  min-height: 220px;
  border-radius: 0.25rem;

  textarea {
    width: 100%;
    min-height: 200px;
    resize: none;
    border: none;
    background-color: inherit;
    padding: 0.5rem;
    font-size: 0.85rem;
  }

  span {
    position: absolute;
    bottom: 0.25rem;
    right: 0.5rem;
    color: ${({ spanColor }) => spanColor || 'lime'};
  }
`;

import React from 'react';

import { ImageLogotipo } from '@assets/images';
import { SubtitleSecondary, Paragraph, LinkMain } from '@components/index';
import { ConfigRoutes } from '@config/index';

import { Container } from './styles';

const Maintenance: React.FC = () => {
  return (
    <Container>
      <LinkMain route={ConfigRoutes.rebox.publics.landingPage.path}>
        <ImageLogotipo className="logotipo" style={{ cursor: 'pointer' }} />
      </LinkMain>
      <SubtitleSecondary fontSize={30} style={{ margin: '6vh 0 2vh' }}>
        Voltamos logo!
      </SubtitleSecondary>
      <Paragraph fontSize={18} nameColor="black" fontWeight={600}>
        Em breve, novidades que facilitarão o seu trabalho!
      </Paragraph>
    </Container>
  );
};

export default Maintenance;

// ./src/pages/errors/NotFound/index.tsx
import React from 'react';

import { useHistory } from 'react-router-dom';

import { LottieNotFound } from '@assets/animations';
import { SubtitleMain, Paragraph, ButtonDefault } from '@components/index';
import { ConfigRoutes } from '@config/index';

import { ContainerNotFound } from './styles';

const NotFound: React.FC = () => {
  const { push } = useHistory();

  return (
    <>
      <ContainerNotFound>
        <LottieNotFound />
        <SubtitleMain fontSize={30} fontWeight={600}>
          Oops!
        </SubtitleMain>
        <Paragraph
          nameColor="black"
          fontSize={20}
          fontWeight={500}
          style={{ margin: '2vh 0 3vh' }}
        >
          Página não encontrada
        </Paragraph>
        <ButtonDefault
          style={{ maxWidth: 180 }}
          onClick={() => push(ConfigRoutes.rebox.publics.landingPage.path)}
        >
          Ir para o início
        </ButtonDefault>
      </ContainerNotFound>
    </>
  );
};

export default NotFound;

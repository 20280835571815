import React, { useCallback } from 'react';

import { Link, useHistory } from 'react-router-dom';

import { ButtonMain, ButtonOutline, LinkMain } from '@components/index';
import { ConfigValues, ConfigBase, ConfigRoutes } from '@config/index';
import Product from '@models/Product';
import { apiRebox, newContractStorageService } from '@services/index';
import { formatMoney, formatText } from '@utils/formatters';

import {
  AnnualSubscriptionText,
  BorderHeader,
  BorderHeaderText,
  Container,
  EconomizeDiscount,
  EconomizeDiscountText,
  Economy,
  MaximumUsesText,
  PriceBig,
  PriceCents,
  PriceColumn,
  PriceContainer,
  PricePerMonthText,
  PricePrefix,
  ProductItem,
  ScratchedPrice,
  Title,
  Buttons,
} from './styles';

interface ICardProps {
  product: Product;
  handleClose: (e: any) => void;
  setProduct: (e: any) => void;
  setFormVehicle: (e: any) => void;
}

const Card: React.FC<ICardProps> = ({
  product,
  handleClose,
  setProduct,
  setFormVehicle,
}) => {
  const { push } = useHistory();

  const getDescount = (current_price: number, promotional_price: number) => {
    let discount = promotional_price * 100;
    discount /= current_price;
    discount = 100 - discount;
    return discount.toFixed(0);
  };

  const getAnnualPrice = () => {
    const { current_price, promotional_price, coverage_months_limit } = product;
    const price_new = promotional_price || current_price;
    return formatMoney.fromNumberToPrice(price_new * coverage_months_limit);
  };

  const handleNavigateToCheckout = useCallback((selectedProduct: Product) => {
    newContractStorageService.updateProduct({
      id: selectedProduct.id,
      query: selectedProduct.classification,
      field_type: 'PRODUCT_ID',
    });
    newContractStorageService.updateCart(product);
    setProduct(newContractStorageService.getCart());
    setFormVehicle((currentForm: any) => [
      ...currentForm,
      {
        vehicle_id: currentForm[currentForm.length - 1].vehicle_id + 1,
        brand: '',
        license_plate: '',
        year: 0,
        model: '',
        color: '',
      },
    ]);
    push(ConfigRoutes.rebox.publics.checkout.path);
  }, []);

  const [price, cents] = formatMoney
    .fromNumberToPrice(product.promotional_price)
    .replace('R$', '')
    .trim()
    .split(',');

  return (
    <Container
      className={
        product.tag === ConfigValues.rebox.product.tag.best_seller
          ? 'bestsellers'
          : ''
      }
    >
      {product.tag === ConfigValues.rebox.product.tag.best_seller && (
        <BorderHeader>
          <BorderHeaderText>MAIS VENDIDO</BorderHeaderText>
        </BorderHeader>
      )}
      <Title>{product.name}</Title>
      <Economy>
        <ScratchedPrice>
          De {formatMoney.fromNumberToPrice(product.current_price)}
        </ScratchedPrice>
        <EconomizeDiscount>
          <EconomizeDiscountText>
            Economize{' '}
            {getDescount(product.current_price, product.promotional_price)}%
          </EconomizeDiscountText>
        </EconomizeDiscount>
      </Economy>

      <PriceContainer>
        <PricePrefix>R$</PricePrefix>
        <PriceBig>{price}</PriceBig>
        <PriceColumn>
          <PriceCents>{cents}</PriceCents>
          <PricePerMonthText>por mês</PricePerMonthText>
        </PriceColumn>
      </PriceContainer>

      <AnnualSubscriptionText>
        Total no ano {getAnnualPrice()}
      </AnnualSubscriptionText>

      <Buttons>
        <ButtonMain
          onClick={() => {
            handleNavigateToCheckout(product || '');
            handleClose(false);
          }}
        >
          Adicionar ao carrinho
        </ButtonMain>
      </Buttons>
    </Container>
  );
};

export default Card;
